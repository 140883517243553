
import { defineComponent, ref } from 'vue';
import { format } from 'date-fns';
import { props, components, emits, sizesFixed } from '@/mixins/view';

export default defineComponent({
  components,
  props,
  emits,
  setup(props, { emit }) {
    let timezoneStr = '';
    const onDismiss = () => emit('dismiss');

    const hours = ref(0);
    const minutes = ref(0);
    const seconds = ref(0);

    const getTimeZoneStr = (date: Date) => {
      const parts = [];
      parts.push(new window.Intl.DateTimeFormat().resolvedOptions().timeZone);
      parts.push(format(date, 'zzzz'));
      return parts.filter(a => a).join(' ');
    };

    const setTime = () => {
      const date = new Date();
      timezoneStr = getTimeZoneStr(date);
      const s = date.getSeconds();
      const m = date.getMinutes();
      const h = date.getHours();
      seconds.value = s * 6;
      minutes.value = m * 6;
      hours.value = h * 30 + m / 2;
    };

    setTime();

    setInterval(() => {
      seconds.value += 6;
      if (seconds.value % 360 === 0) {
        minutes.value += 6;
      }
    }, 1000);

    return {
      sizes: sizesFixed(300, 400),
      onDismiss,
      seconds,
      minutes,
      hours,
      timezoneStr,
    };
  },
});
