<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 16"
    fill="none"
    shape-rendering="crispEdges"
  >
    <g fill="currentColor">
      <rect x="4" y="3" width="4" height="4" />
      <rect x="10" y="3" width="4" height="4" />
      <rect x="16" y="3" width="4" height="4" />
      <rect x="4" y="9" width="4" height="4" />
      <rect x="10" y="9" width="4" height="4" />
      <rect x="16" y="9" width="4" height="4" />
    </g>
  </svg>
</template>
