<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    shape-rendering="crispEdges"
  >
    <rect
      x="4"
      y="5"
      width="16"
      height="14"
      stroke-width="1"
      fill="none"
      class="stroke-current"
    />
    <line
      x1="4"
      x2="20"
      y1="9"
      y2="9"
      stroke-width="1"
      class="stroke-current"
    />
  </svg>
</template>
