<template>
  <div
    ref="root"
    class="w-40 flex flex-col shadow text-sm border border-gray-700 rounded overflow-hidden"
  >
    <div v-for="group of groups" :key="group.key" :class="$style.group">
      <button
        v-for="button of group.buttons"
        :key="button.key"
        :class="$style.button"
        @click="onButtonClick(button)"
      >
        <span class="inline-block w-3 text-center">{{
          button.active ? '&check;' : undefined
        }}</span>
        {{ button.title }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, PropType, ref } from 'vue';
import { MenuGroup, MenuButton } from '@/types/basic';

export default defineComponent({
  props: {
    groups: {
      type: Array as PropType<MenuGroup[]>,
      required: true,
    },
  },
  emits: ['select', 'dismiss'],
  setup(props, { emit }) {
    const root = ref<HTMLElement | null>(null);
    const onButtonClick = (button: MenuButton) => {
      emit('select', button);
      emit('dismiss');
    };
    const onKeyUp = (e: KeyboardEvent) => {
      const key = e.key || e.keyCode;
      if (key === 'Escape' || key === 'Esc' || key === 27) {
        emit('dismiss');
      }
    };
    const onOutsideClick = (e: MouseEvent | TouchEvent) => {
      if (
        root.value &&
        e.target instanceof HTMLElement &&
        !root.value.contains(e.target)
      ) {
        emit('dismiss');
      }
    };
    onMounted(() => {
      window.addEventListener('click', onOutsideClick);
      window.addEventListener('keyup', onKeyUp);
    });
    onUnmounted(() => {
      window.removeEventListener('click', onOutsideClick);
      window.removeEventListener('keyup', onKeyUp);
    });
    return { root, onButtonClick };
  },
});
</script>

<style lang="postcss" module>
.group {
  @apply w-full border-b border-gray-500;
}

.group:last-child {
  @apply border-none;
}

.button {
  @apply w-full text-left p-1;
  @apply text-black bg-gray-300;
}
.button:hover {
  @apply bg-gray-400;
}
.button:focus {
  @apply outline-none;
}
</style>
