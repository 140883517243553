// @TODO TYPE THIS FFS

const isNumber = (val: unknown) => typeof val === 'number';

export function getEventClientPos(e: any) {
  const touch =
    isNumber(e.clientX) && isNumber(e.clientY)
      ? { clientX: e.clientX, clientY: e.clientY }
      : e.touches[0] || e.changedTouches[0];
  const x = touch.clientX;
  const y = touch.clientY;
  return { x, y };
}
