import { CartItem, ShopifyCheckout } from '@/types/product';

function hasAllKeys(required: Array<string>, item: Record<string, unknown>) {
  return Object.keys(item).every((key) => (required as string[]).includes(key));
}

function validateCart(items: unknown): boolean {
  const requiredKeys: Array<keyof CartItem> = [
    'key',
    'productId',
    'productTitle',
    'productHandle',
    'variantId',
    'variantTitle',
    'price',
    'quantity',
  ];
  if (!Array.isArray(items)) return false;
  return items.every(
    (item) =>
      hasAllKeys(requiredKeys, item) &&
      Number.isInteger(item.quantity) &&
      (item.quantity as number) <= 10
  );
}

function validateCheckout(checkout: unknown): boolean {
  return (
    checkout instanceof Object &&
    Object.keys(checkout).length === 2 &&
    'id' in checkout &&
    'url' in checkout
  );
}

function validateCheckoutTimestamp(timestamp: number): boolean {
  if (timestamp) {
    // 30 seconds
    const interval = 30000;
    const now = Date.now();
    const difference = now - timestamp;
    if (difference > interval) return false;
  }
  return true;
}

function getCart(): CartItem[] {
  const fallback = new Array<CartItem>();
  try {
    const raw: string = localStorage.getItem('cart') || '[]';
    const data = JSON.parse(raw);
    if (!validateCart(data)) throw 'invalid';
    return data as CartItem[];
  } catch {
    localStorage.removeItem('cart');
    return fallback;
  }
}

function getCheckout(): ShopifyCheckout {
  const fallback = { id: '', url: '' };
  try {
    const raw = localStorage.getItem('checkout') || '';
    const data = JSON.parse(raw);
    if (!validateCheckout(data)) throw 'invalid';
    return data as ShopifyCheckout;
  } catch {
    localStorage.removeItem('checkout');
    return fallback;
  }
}

function getCheckoutTimestamp(): number {
  const fallback = 0;
  try {
    const raw = localStorage.getItem('checkoutTimestamp') || '';
    const data = parseInt(raw, 10);
    if (!validateCheckoutTimestamp(data)) throw 'invalid';
    return data || 0;
  } catch {
    localStorage.removeItem('cart');
    localStorage.removeItem('checkout');
    return fallback;
  } finally {
    localStorage.removeItem('checkoutTimestamp');
  }
}

export function getLocalStorage(): {
  cart: CartItem[];
  checkout: ShopifyCheckout;
  timestamp: number;
} {
  const timestamp = getCheckoutTimestamp();
  const checkout = getCheckout();
  const cart = getCart();
  return { cart, checkout, timestamp };
}

export function saveCheckoutTimestamp(): void {
  localStorage.setItem('checkoutTimestamp', Date.now().toString());
}

export function saveCart(cart: CartItem[]): void {
  localStorage.setItem('cart', JSON.stringify(cart));
}

export function saveCheckout(checkout: ShopifyCheckout): void {
  localStorage.setItem('checkout', JSON.stringify(checkout));
}
