<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 188.241 188.244">
    <path
      fill="#087C7F"
      d="M135.534 45.179H45.182v75.295H143.063V45.179h-7.529zM90.356 97.887h30.117v7.53H90.356v-7.53zM67.768 52.708v7.529h7.53v7.53h7.529v7.53h7.529v7.529h-7.529v7.529h-7.529v7.53h-7.53v7.53h-7.53v-7.53h7.53v-7.53h7.53v-7.529h7.529v-7.529h-7.529v-7.53h-7.53v-7.53h-7.53v-7.529h7.53z"
    />
    <path fill="#008042" d="M135.534 158.126H120.475v7.529h22.588v-7.529z" />
    <path fill="#5FBB47" d="M135.534 150.596H120.475v7.53h22.588v-7.53z" />
    <g fill="#77CABC">
      <path
        d="M60.237 52.708h7.53v7.529h-7.53zM67.768 60.237h7.53v7.53h-7.53zM75.298 82.827h7.529v7.529h-7.529zM75.298 67.768h7.529v7.53h-7.529zM82.827 75.298h7.529v7.529h-7.529zM67.768 90.356h7.53v7.53h-7.53zM60.237 97.887h7.53v7.53h-7.53z"
      />
    </g>
    <path fill="#77CABC" d="M112.944 97.887H90.356v7.53h30.119v-7.53z" />
    <path
      d="M143.063 37.648H37.652v90.355H150.592V37.648h-7.529zm0 15.06v67.766H45.182V45.179H143.063v7.529zM30.119 30.119h7.529v7.529h-7.529zM150.592 30.119h7.531v7.529h-7.531zM150.592 128.007h7.531v7.529h-7.531zM30.119 128.007h7.529v7.529h-7.529z"
    />
    <g fill="#58595B">
      <path
        d="M37.648 37.648H150.592v-7.529h7.532V22.59H30.119v7.529h7.529zM150.592 128.007H37.648v7.529h-7.529v7.529h128.005v-7.529h-7.532z"
      />
      <path
        d="M37.648 128.007V37.648h-7.529v-7.529H22.59v105.417h7.529v-7.529zM158.124 30.119v7.529h-7.532v90.359h7.532v7.529h7.529V30.119z"
      />
    </g>
    <path
      fill="#A7A9AC"
      d="M173.182 15.06V7.53H15.06v7.53H7.53v150.59h7.529v7.529h158.117v-7.529h7.529V15.06h-7.523zm-7.529 22.588v97.884h-7.529v7.529H30.124v-7.529H22.59V30.119h7.529V22.59H158.119v7.529h7.529v7.529zm-30.119 128.007H120.475V150.596h22.588V165.655h-7.529z"
    />
    <g>
      <path
        d="M30.119 7.529h143.063V0H15.06v7.529h7.53zM7.53 7.529h7.529v7.53H7.53zM173.182 7.529h7.529v7.53h-7.529zM180.711 15.06v150.595h7.53V15.06zM173.182 173.185h7.529v.001h7.53v-7.53h-7.53v-.001h-7.529z"
      />
      <path
        d="M173.182 173.185H15.06v.001H7.53v7.529h7.53v7.529H173.182v-7.529h7.529v-7.529h-7.529zM7.53 165.655V15.06H.001v150.595H0v7.53h15.06v-7.53z"
      />
    </g>
  </svg>
</template>
