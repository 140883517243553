<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 225.887 225.887">
    <g fill="#02A69C">
      <path
        d="M128.003 7.529h-7.53V0H105.414v7.529h-7.53v7.53h-7.529v7.53H135.533v-7.53h-7.53zM30.118 75.295H15.059v7.53H7.53v7.529H0v7.53H15.059v7.53h7.529v7.529h7.53v7.531h7.53V67.766h-7.53zM218.358 90.354v-7.529h-7.53v-7.53H195.769v-7.529h-7.53V120.474h7.53v-7.531h7.529v-7.529h7.53v-7.53H225.887v-7.53z"
      />
    </g>
    <g fill="#2B3990">
      <path
        d="M135.533 45.177H90.355v7.53H143.062v-7.53zM143.062 52.707h7.529v7.529h-7.529zM82.825 52.707h7.53v7.529h-7.53zM75.296 67.766h-7.53V82.825h7.53V67.766h7.529v-7.53h-7.529zM158.121 75.295V60.236h-7.53v15.06h7.53V105.414h7.529V75.295zM60.237 90.354v22.589h7.529V82.825h-7.529zM150.592 105.414h7.53v7.529h-7.53zM135.533 112.943V75.296h7.529v-7.53H97.884v7.53h-7.529v7.53h-7.53V105.415h7.53v7.529h7.529v7.53h22.589v-7.53h7.53v7.53h22.588v-7.53h-15.058zm-22.589-7.529v7.529h-7.53v-7.529h-7.53v-7.53h-7.529v-7.53h7.529V75.296h30.119v22.588h-7.53v7.53h-7.529z"
      />
      <path
        d="M67.766 120.474v7.529h7.53v7.529H90.355v7.53h37.648v-7.53h7.53v-7.529h-7.53v7.529H90.355v-7.529h-7.53v-7.53h-7.529v-7.53h-7.53v7.53z"
      />
    </g>
    <path
      fill="#E6E7E8"
      d="M180.71 22.588H37.648v97.883h7.529V128h7.53v7.529h7.53v7.529h7.529v7.529h90.355v-7.529h7.529v-7.529h7.53V128h7.53v-7.529h7.529V22.588h-7.529zm-15.06 60.237V105.414h-7.529v7.529h-7.53v7.529h-22.588v-7.529h-7.53v7.529H97.884v-7.529h-7.529v-7.529h-7.53V82.825h7.53v-7.529h7.529v15.058h-7.529v7.53h7.529v7.53h7.53v7.529h7.53v-7.529h7.529v-7.53h7.53V75.295H97.884v-7.529h45.178v7.529h-7.529v37.648h15.058v-7.529h7.53V75.296h7.529v7.529zM75.296 60.236h7.529v-7.529h7.53v-7.53H143.062v7.53h7.529v7.529h7.53v15.058h-7.53V60.236h-7.529v-7.529H90.355v7.529h-7.53v7.529h-7.529v-7.529zm-7.53 67.767V112.944h-7.529V82.826h7.529V67.768h7.53v15.058h-7.53V112.944h7.53v7.53h7.529v7.529h7.53v7.529H75.296v-7.529h-7.53zm60.237 0h7.53v7.529h-7.53v-7.529zm-7.53 15.059H90.355v-7.529h37.648v7.529h-7.53z"
    />
    <g fill="#77CABC">
      <path
        d="M60.237 135.532h-7.53v-7.529h-7.53v-7.53H30.118v-7.53h-7.53v-7.529h-7.529v-7.53H0v120.473h7.53v-7.529h7.529v-7.53h7.529v-7.529h7.53v-7.53h7.53v-7.53h7.529v-7.529h7.53v-7.53h7.53v-7.529h7.529V143.062h-7.529zM218.358 97.884h-7.53v7.53h-7.53v7.529h-7.529v7.53H180.71v7.53h-7.53v7.529h-7.53v7.53h-7.529V158.121h7.529v7.529h7.53v7.53h7.53v7.529h7.529v7.53h7.53v7.53h7.529v7.529h7.53v7.53h7.53v7.529h7.529V97.884z"
      />
    </g>
    <path
      fill="#91D1BC"
      d="M218.358 218.357v-7.529h-7.53v-7.53h-7.53v-7.529h-7.529v-7.53h-7.53v-7.53h-7.529v-7.529h-7.53v-7.53h-7.53v-7.529h-7.529v-7.53H67.766v7.53h-7.529v7.529h-7.53v7.53h-7.53v7.529h-7.529v7.53h-7.53v7.53h-7.53v7.529h-7.529v7.53H7.53v7.529H0v7.53H225.887v-7.53z"
    />
  </svg>
</template>
