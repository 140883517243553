<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180.711 180.71">
    <g fill="#087C7F">
      <path
        d="M90.355.001L82.826 0l-7.53.001H52.708L45.179 0l-7.531.001H15.06V7.53H7.53v7.53H0l.001 7.529L0 22.59V67.767l.001 7.529H0v22.589h7.53v-7.529l7.53-.001v-7.529h7.53l-.001-7.529.001-.001-.001-7.529h.001l-.001-7.529.001-.001-.001-7.529.001-.001V37.648h7.529v-7.529h7.529V22.59h7.53v-7.53H90.355V7.53h7.53V.001z"
      />
      <path
        d="M52.708 30.119h-7.529l-.001 7.529-7.53.001v22.588h7.53v-7.529h7.53l-.001-7.529h7.53v-7.531h7.53v-7.529h-7.53z"
      />
    </g>
    <g fill="#02A69C">
      <path
        d="M90.355 15.06H135.533v7.53h7.53v7.529h7.529l.001-7.529h7.529v-7.53h7.529V.001h-7.529L150.593 0l-7.53.001h-30.119V0l-7.529.001h-7.53V7.53h-7.53zM135.533 30.119H67.767v7.529h-7.529v7.529h-7.53v7.53l-7.529-.001v7.53H37.65v75.295h7.529v-7.53l7.529.001v-7.53h7.53v-15.06h-7.53V75.295h7.53v-7.53h7.529v-7.529h7.529v-7.53h7.53l7.529.001H105.414v7.529H120.473v-7.529l-7.529-.001v.001h-.001v-7.529h7.53v-7.531h7.529v7.53h7.53v-7.53l.001-7.528z"
      />
      <path
        d="M90.355 82.826h7.53v-7.529l7.53-.001v-7.529H75.296v7.53l-7.529-.001V105.415h7.529v-7.529l7.53-.001v-7.529h7.529zM15.06 82.826h7.53v7.529h-7.53zM30.119 143.062v-7.529H22.59l-.001-7.529v-7.53l.001-7.53v-7.53h-.001V90.355H7.53v7.529H.001v67.764h15.058v-7.529l7.53-.001-.001-7.529h7.53l.001-7.527z"
      />
    </g>
    <g fill="#77CABC">
      <path
        d="M158.122 22.589l-7.529.001-.001 7.529v7.53l7.53-.001v52.708h7.529v-7.53h7.53v-7.53h7.53l-.001-7.529V15.06h-7.53l.001-7.53h-7.53v7.53h-7.529zM112.945 97.885l-.001-7.529.001-7.53v-7.53h-15.06v7.53h-7.53v7.529h-7.529v7.53l-7.53.001V112.945l7.53-.001H105.415v-7.528l7.53-.001z"
      />
      <path
        d="M128.003 120.474h7.53v-7.53h7.53V45.178h-.001l.001-7.53h-7.53v7.531-.001 7.529l-7.53.001v7.529h-7.529l-7.53.001v7.529l7.53.001v7.528h7.529v30.118l-7.529.001v7.529l-7.53.001v7.529h-7.529v7.529l-7.53.001h-7.53l-7.529-.001-7.53.001v-7.53h-7.529v-7.53h-7.529l-.001 7.53h-7.529v7.53l-7.53-.001v15.06h67.766v-7.53h7.53v-7.529h7.529zM90.355 158.122h-7.53l-7.529-.001H60.237l-7.529.001-7.53-.001v-7.529h-7.529l-.001-7.529-7.529-.001v7.53h-7.53l.001 7.529h-7.53v7.53l-7.53.001v7.529h7.53v7.529h22.588v.001l7.53-.001H60.237l7.53.001h7.529v-7.53h7.53v-7.53h7.529z"
      />
    </g>
    <g fill="#A7D8BC">
      <path
        d="M112.944 143.063H135.533l-.001-7.53h7.531V112.944h-7.53v7.53h-7.53v7.53h-7.529v7.529h-7.529z"
      />
      <path
        d="M173.181 165.651h7.529v-7.53l.001-7.529-.001-7.529V82.826l.001-7.53h-7.53v7.53h-7.53v7.529h-7.529v45.178h-7.53l.001 7.53h-.001l-7.529-.001v7.53h-7.531l.001 7.529H112.944l-7.529.001h-15.06v7.529h-7.529v7.53h-7.53v7.53l7.53-.001H112.944v.001l7.53-.001H143.063l7.529.001 7.53-.001h7.529v-7.529h7.53z"
      />
    </g>
  </svg>
</template>
