<template>
  <ContextMenu
    ref="root"
    class="fixed"
    :style="style"
    :groups="groups"
    @select="onSelect"
    @dismiss="dismiss"
  />
</template>

<script lang="ts">
import {
  ComponentPublicInstance,
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from 'vue';
import { useStore } from '@/store';
import { useRouter } from '@/router';
import ContextMenu from '@/components/ContextMenu.vue';
import { MenuButton, MenuGroup } from '@/types/basic';

export default defineComponent({
  components: {
    ContextMenu,
  },
  props: {
    x: { type: Number, required: true },
    y: { type: Number, required: true },
  },
  emits: ['dismiss', 'shutdown'],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const dimensions = reactive({ x: 0, y: 0 });
    const max = reactive({ x: 0, y: 0 });
    const root = ref<ComponentPublicInstance | null>(null);
    const active = ref(false);
    const style = computed(() => {
      let x = props.x;
      let y = props.y;
      if (x >= max.x) x -= dimensions.x;
      if (y >= max.y) y -= dimensions.y;
      return {
        left: `${x}px`,
        top: `${y}px`,
      };
    });
    onMounted(() => {
      if (root.value) {
        dimensions.x = root.value.$el.offsetWidth;
        dimensions.y = root.value.$el.offsetHeight;
      }
      max.x = window.innerWidth - dimensions.x;
      max.y = window.innerHeight - dimensions.y;
    });
    const dismiss = () => emit('dismiss');
    const onSelect = (button: MenuButton) => {
      if (button.key === 'bg-show') {
        store.commit.toggleBackground('space');
        dismiss();
      } else if (button.key === 'credits') {
        router.push({ name: 'credits' });
      } else if (button.key === 'shutdown') {
        emit('shutdown');
      }
    };
    const groups = computed<MenuGroup[]>(() => [
      {
        key: 'bg',
        buttons: [
          {
            key: 'bg-show',
            active: store.state.backgrounds.space,
            title: 'Enable 3D',
          },
        ],
      },
      {
        key: 'credits',
        buttons: [
          {
            key: 'credits',
            active: false,
            title: 'Credits',
          },
          {
            key: 'shutdown',
            active: false,
            title: 'Shutdown',
          },
        ],
      },
    ]);
    return {
      active,
      dismiss,
      groups,
      onSelect,
      root,
      style,
    };
  },
});
</script>
