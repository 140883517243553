
import { defineComponent } from 'vue';
import { props, components, emits } from '@/mixins/view';
import { useViewport } from '@/router/viewport';

export default defineComponent({
  components,
  props,
  emits,
  setup(props, { emit }) {
    const viewportDimensions = useViewport();
    const width = 300;
    const height = 300;
    const x = viewportDimensions.w / 2 - width / 2;
    const y = viewportDimensions.h / 2 - height / 2;
    const onDismiss = () => emit('dismiss');
    return { sizes: { x, y, width, height }, onDismiss };
  },
});
