import { PropType } from 'vue';
import BaseWindow from '@/components/BaseWindow.vue';
import { RouteLocation, RouteLocationRaw } from '@/types/router';
import { useViewport } from '@/router/viewport';

const getRandomDimension = (dimension: number, multiplier: number) =>
  Math.floor(Math.random() * dimension * multiplier) + 1;

const getRandomDimensionPx = (max: number, width: number) =>
  Math.floor(Math.random() * (max - width + 1));

export const components = {
  BaseWindow,
};

export const props = {
  view: {
    type: Object as PropType<RouteLocation | RouteLocationRaw>,
    required: true,
  },
};

export const emits = ['dismiss'];

export function sizes(
  [wideWidth, wideHeight] = [0.6, 0.6],
  [narrowWidth, narrowHeight] = [0.9, 0.9]
): { x: number; y: number; height: number; width: number } {
  const viewportDimensions = useViewport();
  const aspectRatio = viewportDimensions.h / viewportDimensions.w;
  const widthProportion = aspectRatio > 1 ? narrowWidth : wideWidth;
  const heightProportion = aspectRatio > 1 ? narrowHeight : wideHeight;
  const x = getRandomDimension(viewportDimensions.w, 1 - widthProportion);
  const y = getRandomDimension(viewportDimensions.h, 1 - heightProportion);
  const width = viewportDimensions.w * widthProportion;
  const height = viewportDimensions.h * heightProportion;
  return { x, y, height, width };
}

export function sizesFixed(
  width = 300,
  height = 300
): { x: number; y: number; height: number; width: number } {
  const viewportDimensions = useViewport();
  const x = getRandomDimensionPx(viewportDimensions.w, width);
  const y = getRandomDimensionPx(viewportDimensions.h, height);
  return { x, y, height, width };
}
