<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240.95 240.95">
    <path
      fill="#f8afa5"
      d="M188.24 120.48v7.53h-15.06v15.06h-7.53v7.53h-7.53v7.53h-22.59v-7.53H128v-15.06h-15.06v15.06h-7.53v7.53H82.83v-7.53H75.3v-7.53h-7.53v-15.06H52.71v-7.53h-7.53v15.06h7.53v15.06h7.53v22.59h7.53v15.06h7.53v7.52h15.06v7.53h15.05v7.54h30.12v-7.54h15.06v-7.53h15.06v-7.52h7.53v-15.06h7.53V150.6h7.53v-15.06h7.53v-15.06h-7.53z"
    />
    <path
      fill="#3ebdac"
      d="M173.18 52.71V30.12h-7.53V15.06h-7.53V7.53H128v7.53h-15.06V7.53H82.83v7.53H75.3v15.06h-7.53v22.59h-7.53v30.12h37.65v7.53h45.17v-7.53h37.65V52.71h-7.53z"
    />
    <path
      fill="#02a69c"
      d="M225.89 82.83V75.3h-15.06v-7.53h-22.59v15.06h-7.53v7.53h-37.65v7.53H97.89v-7.53H60.24v-7.53h-7.53V67.77H30.12v7.53H15.06v7.53H7.53v15.06h15.06v7.53h15.06v7.53h15.06v7.53h30.12v7.53h75.29v-7.53h30.12v-7.53h15.06v-7.53h15.06v-7.53h15.06V82.83h-7.53z"
    />
    <path
      fill="#231f20"
      d="M180.71 120.48h-22.59v7.53H82.83v-7.53H52.71v7.53h15.06v15.06h7.53v7.53h7.53v7.53h22.58v-7.53h7.53v-15.06H128v15.06h7.53v7.53h22.59v-7.53h7.53v-7.53h7.53v-15.06h15.06v-7.53h-7.53z"
    />
    <path
      fill="#fff"
      d="M143.06 188.24h7.53v7.53h-7.53zM135.53 188.24h7.53v7.53h-7.53zM143.06 195.77h7.53v7.53h-7.53zM150.59 195.77h7.53v7.53h-7.53zM150.59 203.3h7.53v7.53h-7.53z"
    />
    <path
      fill="#231f20"
      d="M158.12 195.77h7.53v7.53h-7.53zM135.53 203.3v7.54h-30.12v-7.54H90.36v-7.53H75.3v7.53h7.53v7.54h15.06v7.52h15.05v7.53H128v-7.53h15.06v-7.52h7.53v-7.54h-15.06z"
    />
    <path fill="#f15a29" d="M158.12 203.3h7.53v7.53h-7.53z" />
    <path fill="#ed1c24" d="M158.12 210.84h7.53v7.53h-7.53z" />
    <path
      fill="#231f20"
      d="M143.06 7.53h15.06V0H128v7.53h15.06zM97.89 7.53h15.05V0H82.83v7.53h15.06zM120.47 15.06H128V7.53h-15.06v7.53h7.53zM75.3 7.53h7.53v7.53H75.3zM158.12 7.53h7.53v7.53h-7.53zM75.3 22.59v-7.53h-7.53v15.06h7.53v-7.53zM165.65 30.12h7.53V15.06h-7.53v15.06zM173.18 45.18v7.53h7.53V30.12h-7.53v15.06zM67.77 45.18V30.12h-7.53v22.59h7.53v-7.53zM60.24 67.77V52.71h-7.53v7.53H30.12v7.53h22.59v15.06h7.53V67.77zM90.36 82.83H60.24v7.53h37.65v-7.53h-7.53zM165.65 82.83h-22.59v7.53h37.65v-7.53h-15.06zM128 90.36H97.89v7.53h45.17v-7.53H128zM203.3 60.24h-15.06v-7.53h-7.53v30.12h7.53V67.77h22.59v-7.53h-7.53zM15.06 67.77v7.53h15.06v-7.53H15.06zM218.36 67.77h-7.53v7.53h15.06v-7.53h-7.53zM7.53 75.3h7.53v7.53H7.53zM225.89 75.3h7.53v7.53h-7.53zM233.42 82.83v15.06h7.53V82.83h-7.53zM0 90.36v7.53h7.53V82.83H0v7.53zM15.06 105.42h7.53v-7.53H7.53v7.53h7.53zM218.36 97.89v7.53h15.06v-7.53h-15.06zM30.12 112.95h7.53v-7.53H22.59v7.53h7.53zM203.3 105.42v7.53h15.06v-7.53H203.3zM188.24 112.95v7.53h15.06v-7.53h-15.06zM45.18 120.48h7.53v-7.53H37.65v7.53h7.53zM22.59 120.48v7.53h15.06v-7.53H22.59zM210.83 128.01h7.53v-7.53H203.3v7.53h7.53zM15.06 128.01h7.53v7.53h-7.53zM218.36 128.01h7.53v7.53h-7.53zM7.53 135.54h7.53v7.53H7.53zM225.89 135.54h7.53v7.53h-7.53zM0 150.6h7.53v7.53h7.53v7.53h7.53v7.53h7.53v7.53h7.53v-7.53h-7.53v-7.54h-7.53v-7.52h-7.53v-7.53H7.53v-7.53H0v7.53zM225.89 150.6v7.53h-7.53v7.52h-7.53v7.54h-7.53v7.53h7.53v-7.53h7.53v-7.53h7.53v-7.53h7.53v-7.53h7.53v-7.53h-7.53v7.53h-7.53zM37.65 180.72h7.53v7.53h-7.53zM195.77 180.72h7.53v7.53h-7.53zM210.83 188.25h-7.53v15.05h7.53v-15.05zM37.65 195.77v-7.52h-7.53v15.05h7.53v-7.53zM210.83 210.84h-30.12v7.52h37.65V203.3h-7.53v7.54zM52.71 210.84H30.12v-7.54h-7.53V218.36h37.65v-7.52h-7.53zM75.3 218.36H60.24v7.53h22.59v-7.53H75.3zM97.89 225.89H82.83v7.53h22.59v-7.53h-7.53zM165.65 218.36h-7.53v7.53h-22.59v7.53H105.42v7.53h30.12v-7.52h7.52v-.01h15.06v-7.53h22.59v-7.53h-15.06z"
    />
    <path
      fill="#087c7f"
      d="M225.89 143.07v-7.53h-7.53v-7.53H203.3v-7.53h-7.53v15.06h-7.53v15.06h-7.53v22.59h-7.53v15.06h-7.53v30.11h15.06v-7.52h30.12v-7.54h-7.53v-15.05h-7.53v-7.53h7.53v-7.53h7.53v-7.54h7.53v-7.52h7.53v-7.53h7.53v-7.53h-7.53zM143.06 210.84v7.52H128v7.53h-15.06v-7.53H97.89v-7.52H82.83v-7.54H75.3v-15.05h-7.53v-15.06h-7.53V150.6h-7.53v-15.06h-7.53v-15.06h-7.53v7.53H22.59v7.53h-7.53v7.53H7.53v7.53h7.53v7.53h7.53v7.52h7.53v7.54h7.53v7.53h7.53v7.53h-7.53v15.05h-7.53v7.54h30.12v7.52h22.59v7.53h22.58v7.54h30.12v-7.54h22.59v-15.05h-15.06z"
    />
  </svg>
</template>
