<template>
  <BaseWindow
    title="Shop"
    :loading="!products"
    v-bind="sizes"
    container-class=""
    content-class="bg-gray-shop"
    @dismiss="onDismiss"
  >
    <template #actions>
      <div
        class="border-black border-t border-l border-r bg-gray-shop leading-none flex items-center"
      >
        <div class="p-1 flex">
          <button
            :class="[
              $style.layoutIcon,
              { [$style.layoutIconActive]: layout === 'grid' },
            ]"
            @click="switchLayout('grid')"
          >
            <IconPxGrid class="w-6" />
          </button>
          <button
            :class="[
              $style.layoutIcon,
              { [$style.layoutIconActive]: layout === 'list' },
            ]"
            @click="switchLayout('list')"
          >
            <IconPxList class="w-6" />
          </button>
        </div>
        <div class="ml-auto pr-1">
          <input
            type="text"
            class="text-sm text-gray-800 bg-white border border-gray-800 rounded-sm h-5 px-1"
            v-model="searchInput"
          />
        </div>
      </div>
    </template>
    <div v-if="products">
      <div v-if="searchInput !== '' && products.length === 0" class="p-4">
        Nothing found matching "{{ searchInput }}".<br />
        <button
          class="text-based focus:underline hover:underline"
          @click="searchInput = ''"
        >
          Click to clear your search.
        </button>
      </div>
      <div
        v-else-if="layout === 'grid'"
        class="flex flex-wrap justify-center sm:justify-start"
      >
        <DesktopIcon
          v-for="product of products"
          :key="product.id"
          class="m-2"
          :image="product.image"
          size="xl"
          :text="product.title"
          :to="product.link"
        />
      </div>
      <ProductsList v-else-if="layout === 'list'" :products="products" />
    </div>
  </BaseWindow>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useStore } from '@/store';
import {
  props,
  components as viewComponents,
  emits,
  sizes,
} from '@/mixins/view';
import DesktopIcon from '@/components/DesktopIcon.vue';
import IconPxGrid from '@/components/icons/IconPxGrid.vue';
import IconPxList from '@/components/icons/IconPxList.vue';
import ProductsList from '@/components/ProductsList.vue';

export default defineComponent({
  components: {
    ...viewComponents,
    DesktopIcon,
    IconPxGrid,
    IconPxList,
    ProductsList,
  },
  props,
  emits,
  setup(props, { emit }) {
    const store = useStore();
    const layout = computed(() => store.state.shopLayout);
    // const layout = ref('grid');
    const searchInput = ref('');
    const switchLayout = (value: string) => {
      store.commit.shopLayoutSet(value);
    };

    const onDismiss = () => emit('dismiss');
    const products = computed(() => {
      const searchLower = searchInput.value.toLowerCase();
      return store.state.products
        ?.map((p) => ({
          id: p.id,
          image: p.image,
          title: p.title,
          type: p.type,
          link: { name: 'product', param: p.handle },
          variants: p.variants,
          searchStr: p.title.toLowerCase(),
        }))
        .filter((p) => p.searchStr.includes(searchLower));
    });

    return {
      layout,
      switchLayout,
      products,
      onDismiss,
      searchInput,
      sizes: sizes(),
    };
  },
});
</script>

<style lang="postcss" module>
.layoutIcon {
  @apply border border-gray-400 text-gray-400 rounded-sm mr-1;
}

.layoutIcon:active,
.layoutIcon:focus {
  @apply outline-none;
}

.layoutIconActive {
  @apply border-gray-800 text-gray-800;
}
</style>
