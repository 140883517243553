<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="none"
    shape-rendering="crispEdges"
  >
    <rect
      x="2"
      y="2"
      width="12"
      height="12"
      stroke="currentColor"
      stroke-width="2"
    />
    <line
      x1="8"
      y1="8"
      x2="8"
      y2="5"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="square"
    />
    <line
      x1="8"
      y1="8"
      x2="10"
      y2="8"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="square"
    />
  </svg>
</template>
