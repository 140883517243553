
import { defineComponent, ref, watch } from 'vue';
import DesktopIcon from '@/components/DesktopIcon.vue';
import IconShop from '@/components/icons/IconShop.vue';
import IconLookbook from '@/components/icons/IconLookbook.vue';
import IconAbout from '@/components/icons/IconAbout.vue';
import IconContact from '@/components/icons/IconContact.vue';
import IconCart from '@/components/icons/IconCart.vue';
import { useRouter } from '@/router';
import { useStore } from '@/store';
import { getEventClientPos } from '@/util/event-pos';

export default defineComponent({
  components: {
    DesktopIcon,
    IconShop,
    IconLookbook,
    IconAbout,
    IconContact,
    IconCart,
  },
  props: {
    cartCount: {
      type: Number,
      required: true,
    },
  },
  emits: ['rightclick'],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const iconCount = ref(0);
    watch(
      () => store.state.initialized,
      (initialized) => {
        if (initialized) {
          iconCount.value += 1;
        } else {
          iconCount.value = 0;
        }
      }
    );
    // Transitions
    const afterEnter = () => {
      iconCount.value += 1;
    };
    const openView = (name: string) => {
      router.push({ name });
    };

    // Menu
    const onRightClick = (e: MouseEvent | TouchEvent) => {
      emit('rightclick', getEventClientPos(e));
    };
    return {
      afterEnter,
      iconCount,
      onRightClick,
      openView,
    };
  },
});
