import {
  ref,
  inject,
  h,
  defineComponent,
  onMounted,
  reactive,
  provide,
  TransitionGroup,
  watch,
  InjectionKey,
} from 'vue';
import { useRoute, useRouter } from '.';
import { useStore } from '@/store';
import { BasedWindowWrapper } from '@/router/window-wrapper';
import { BasedView } from '@/router/view-wrapper';
import { RouterWindow, RouterView, ViewportDimensions } from '@/types/router';

export const viewportKey: InjectionKey<ViewportDimensions> = Symbol('vp');

export const BasedViewport = defineComponent({
  name: 'BasedViewport',
  props: {
    windowDimensions: {
      type: Object,
      required: true,
    },
    transitionName: {
      type: String,
      default: '',
    },
    transitionDuration: {
      type: Number,
      default: 500,
    },
  },
  setup(props, context) {
    const router = useRouter();
    const store = useStore();
    const currentRoute = useRoute();

    const isMounted = ref(false);
    const viewport = ref<HTMLElement | null>(null);
    const dimensions = reactive({
      h: 0,
      w: 0,
    });
    provide(viewportKey, dimensions);

    const setDimensions = () => {
      if (viewport.value) {
        dimensions.h = viewport.value.offsetHeight;
        dimensions.w = viewport.value.offsetWidth;
      }
    };

    watch(() => props.windowDimensions, setDimensions, { deep: true });

    onMounted(() => {
      setDimensions();
      isMounted.value = true;
    });

    const containerClasses =
      'absolute top-0 left-0 w-full h-full pointer-events-none will-change-transform';

    const mapWindow = (view: RouterWindow) => {
      return h(BasedWindowWrapper, {
        class: containerClasses,
        key: view.id,
        view,
      });
    };

    const mapView = (view: RouterView) => {
      return h(BasedView, {
        class: containerClasses,
        key: view.name + (view.param || ''),
        view,
      });
    };

    return () => {
      const views =
        isMounted.value && store.state.initialized
          ? router.getViews(currentRoute)
          : [];

      const mappedViews = [
        ...views.map(mapView),
        ...router.windows.value.map(mapWindow),
      ];

      const renderedViews = props.transitionName
        ? h(
            TransitionGroup,
            { name: props.transitionName, duration: props.transitionDuration },
            () => mappedViews
          )
        : mappedViews;

      return h(
        'div',
        {
          ref: viewport,
        },
        [context.slots.default ? context.slots.default() : [], renderedViews]
      );
    };
  },
});

export function useViewport(): ViewportDimensions {
  return inject(viewportKey)!;
}
