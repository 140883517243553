import { createApp, App } from 'vue';
import { createRouter } from '@/router';
import { routes } from '@/router/routes';
import { redirects } from '@/router/redirects';
import Desktop from '@/Desktop.vue';
import '@/assets/styles/index.css';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { apolloClient } from '@/graphql/client';
import { createStore } from '@/store';
import { basedAnalytics } from '@/plugins/gtag';

const createBased = () => {
  const router = createRouter(routes, redirects);
  const store = createStore();
  const app = createApp(Desktop);
  app.use(router);
  app.use(store);
  app.provide(DefaultApolloClient, apolloClient);
  app.use(basedAnalytics, {
    id: process.env.VUE_APP_GTAG_ID,
    router,
    debug: process.env.VUE_APP_GTAG_DEBUG,
  });
  app.mount('#based');
  return app;
};

let based: App<Element> | null = null;

const startup = () => {
  document.body.className = '';
  based = createBased();
  window.removeEventListener('click', startup);
};

startup();

window.addEventListener('shutdown', () => {
  document.body.className = 'shutdown';
  setTimeout(() => {
    if (based) {
      based.unmount('#based');
      based = null;
      document.body.className = 'sleeping';
      window.addEventListener('click', startup);
    }
  }, 1000);
});
