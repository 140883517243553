import { CartItem } from '@/types/product';

export function formatPrice(price: number): string {
  return `£${price.toFixed(2)}`;
}

export function cartTotal(cart: CartItem[], format?: true): string;
export function cartTotal(cart: CartItem[], format?: false): number;
export function cartTotal(cart: CartItem[], format = true): string | number {
  const cost = cart.reduce((acc, item) => acc + item.quantity * item.price, 0);
  if (format) return formatPrice(cost);
  return cost;
}

export function cartCount(cart: CartItem[]): number {
  return cart.reduce((acc, item) => acc + item.quantity, 0);
}
