import {
  App,
  reactive,
  shallowRef,
  unref,
  computed,
  Ref,
  ref,
  InjectionKey,
  inject,
} from 'vue';
import { BasedLink } from '@/router/link';
import { BasedViewport } from '@/router/viewport';
import {
  AfterPushGuard,
  Redirect,
  Route,
  RouteDefinition,
  RouteLocation,
  RouteLocationRaw,
  RouterView,
  RouterWindow,
} from '@/types/router';

export const routerKey: InjectionKey<Router> = Symbol('r');
export const routeKey: InjectionKey<Route> = Symbol('rl');

export interface Router {
  active: Ref<string>;
  afterPush: (guard: AfterPushGuard) => void;
  currentRoute: Ref<Route>;
  getViews: (route: Route) => Array<RouterView>;
  install: (app: App) => void;
  pull: (rawView: RouteLocationRaw) => void;
  pullWindow: (id: string) => void;
  push: (rawView: RouteLocationRaw) => void;
  pushWindow: (window: RouterWindow) => void;
  resolve: (to: RouteLocationRaw) => string;
  routes: Array<RouteDefinition>;
  top: Ref<number>;
  windows: Ref<Array<RouterWindow>>;
}

type URLLocation = string;

interface URLQuery {
  [key: string]: string[] | undefined;
}

export function createRouter(
  routes: Array<RouteDefinition>,
  redirects: Array<Redirect> = []
) {
  function parseLocation(location: Location): URLLocation {
    const { pathname, search } = location;
    if (pathname !== '/') {
      const redirect = redirects
        .map((r) => {
          const match = r.matcher.exec(location.pathname);
          if (match) return { name: r.to, param: match[1] };
        })
        .find((a) => a);
      if (redirect) {
        const route = routes.find((r) => r.name === redirect.name);
        if (route) {
          const searchString = [redirect.name, redirect.param]
            .filter((a) => a)
            .join('=');
          window.history.replaceState({}, '', `/?${searchString}`);
          return searchString;
        }
      } else {
        window.history.replaceState({}, '', '/');
      }
    }
    return search;
  }

  const startLocation: Route = {
    views: [],
    meta: {},
  };

  let started: boolean | undefined;

  const currentRoute: Ref<Route> = shallowRef(startLocation);
  const { history, location } = window;
  const top = ref(0);
  const active = ref('');
  const windows = ref<Array<RouterWindow>>([]);

  function decode(str: string | number): string {
    try {
      return decodeURIComponent('' + str);
    } catch {
      // @TODO Nothing
    }
    return '' + str;
  }

  function routeToURL(route: Route): URLLocation {
    let url = '/';
    if (route.views.length) {
      const urlParts: {
        [key: string]: Array<string | undefined>;
      } = route.views.reduce((acc, v) => {
        if (!acc[v.name]) {
          acc[v.name] = [v.param];
        } else if (!acc[v.name].includes(v.param)) {
          acc[v.name].push(v.param);
        }
        return acc;
      }, {} as Record<string, Array<string | undefined>>);
      const urlString = Object.entries(urlParts)
        .map(([viewName, params]) => {
          return [viewName, params.filter((a) => a).join(',')]
            .filter((a) => a)
            .join('=');
        })
        .join('&');
      url = `?${urlString}`;
    }
    return url;
  }

  function resolve(to: RouteLocationRaw): string {
    const route: Route = {
      views: [...currentRoute.value.views, to],
      meta: {},
    };
    return `/${routeToURL(route)}`;
  }

  function parseSearchString(search: string): URLQuery {
    const query = {} as URLQuery;
    if (search === '' || search === '?') {
      return query;
    }
    const searchParts = (search[0] === '?' ? search.slice(1) : search).split(
      '&'
    );
    searchParts.forEach((part) => {
      const [rawKey, rawValue] = part.split('=') as [
        string,
        string | undefined
      ];
      const key = decode(rawKey);
      const values = rawValue ? rawValue.split(',') : [];

      if (key in query) {
        query[key]?.push(...values);
      } else {
        query[key] = [...values];
      }
    });
    return query;
  }

  function locationToRoute(location: Location): Route {
    const searchString = parseLocation(location);
    const query = parseSearchString(searchString);

    const views = [] as RouteLocationRaw[];
    for (const key in query) {
      const values = query[key];
      if (values && values.length) {
        values.forEach((value) =>
          views.push({
            name: key,
            param: value,
          })
        );
      } else {
        views.push({ name: key, param: undefined });
      }
    }

    return {
      views,
      meta: {},
    };
  }

  const afterPushGuards: Array<AfterPushGuard> = [];

  function afterPush(callback: AfterPushGuard) {
    afterPushGuards.push(callback);
  }

  function triggerAfterPush(view: RouteLocation) {
    afterPushGuards.forEach((guard) => guard(view));
  }

  function navigate(to: Route, updateHistory = true) {
    const url = routeToURL(to);
    const state = {}; // @TODO
    if (updateHistory) {
      try {
        history.pushState(state, '', url);
      } catch (e) {
        location.assign(url);
      }
    }
    currentRoute.value = to;
  }

  function push(rawView: RouteLocationRaw): void {
    let updateHistory = false;
    const view: RouteLocation = {
      id: rawView.name + (rawView.param || ''),
      ...rawView,
    };
    const from = currentRoute.value;
    const viewIndex = from.views.findIndex(
      (v) => v.name === view.name && v.param === view.param
    );
    const newRoute = { ...from } as Route;
    if (viewIndex < 0) {
      newRoute.views.push(view);
      updateHistory = true;
    }
    active.value = view.id;
    triggerAfterPush(view);
    return navigate(newRoute, updateHistory);
  }

  function pull(view: RouteLocationRaw) {
    const from = currentRoute.value;
    const viewIndex = from.views.findIndex((v) => {
      if (v.name !== view.name) return false;
      if (view.param && v.param !== view.param) return false;
      return true;
    });
    const newRoute = { ...from };
    if (viewIndex > -1) {
      newRoute.views.splice(viewIndex, 1);
    }
    active.value = '';
    return navigate(newRoute);
  }

  function pushWindow(window: RouterWindow) {
    const index = windows.value.findIndex((w) => w.id === window.id);
    if (index < 0) {
      windows.value.push(window);
    }
    active.value = window.id;
  }

  function pullWindow(id: string) {
    const index = windows.value.findIndex((w) => w.id === id);
    if (index > -1) {
      windows.value.splice(index, 1);
    }
    active.value = '';
  }

  function getViews(route: Route): Array<RouterView> {
    const notUndefined = <T>(x: T | undefined): x is T => !!x;
    const views = Array.isArray(route.views) ? route.views : [];
    const matched = views
      .map((v) => {
        const r = routes.find((r) => r.name === v.name);
        if (r) return { ...r, ...v };
        return undefined;
      })
      .filter(notUndefined);
    return matched;
  }

  const popStateHandler = () => {
    const route = locationToRoute(location);
    currentRoute.value = route;
  };

  window.addEventListener('popstate', popStateHandler);

  const router: Router = {
    active,
    afterPush,
    top,
    routes,
    currentRoute,
    resolve,
    push,
    pull,
    pushWindow,
    pullWindow,
    windows,
    getViews,
    install(app: App) {
      app.component('BasedLink', BasedLink);
      app.component('BasedViewport', BasedViewport);
      app.config.globalProperties.$router = this;
      Object.defineProperty(app.config.globalProperties, '$route', {
        get: () => unref(currentRoute),
      });

      const isBrowser = typeof window !== 'undefined';
      if (isBrowser && !started) {
        started = true;
        const route = locationToRoute(location);
        currentRoute.value = route;
      }

      const reactiveRoute = {
        meta: computed(() => currentRoute.value.meta),
        views: computed(() => currentRoute.value.views),
      };

      app.provide(routerKey, router);
      app.provide(routeKey, reactive(reactiveRoute));
    },
  };

  return router;
}

export function useRouter(): Router {
  return inject(routerKey)!;
}

export function useRoute(): Route {
  return inject(routeKey)!;
}
