
import { defineComponent, onMounted, onUnmounted, PropType, ref } from 'vue';
import { MenuGroup, MenuButton } from '@/types/basic';

export default defineComponent({
  props: {
    groups: {
      type: Array as PropType<MenuGroup[]>,
      required: true,
    },
  },
  emits: ['select', 'dismiss'],
  setup(props, { emit }) {
    const root = ref<HTMLElement | null>(null);
    const onButtonClick = (button: MenuButton) => {
      emit('select', button);
      emit('dismiss');
    };
    const onKeyUp = (e: KeyboardEvent) => {
      const key = e.key || e.keyCode;
      if (key === 'Escape' || key === 'Esc' || key === 27) {
        emit('dismiss');
      }
    };
    const onOutsideClick = (e: MouseEvent | TouchEvent) => {
      if (
        root.value &&
        e.target instanceof HTMLElement &&
        !root.value.contains(e.target)
      ) {
        emit('dismiss');
      }
    };
    onMounted(() => {
      window.addEventListener('click', onOutsideClick);
      window.addEventListener('keyup', onKeyUp);
    });
    onUnmounted(() => {
      window.removeEventListener('click', onOutsideClick);
      window.removeEventListener('keyup', onKeyUp);
    });
    return { root, onButtonClick };
  },
});
