<template>
  <div
    ref="el"
    class="pointer-events-auto will-change-transform flex"
    :class="$style[theme]"
    @mousedown="focus"
  >
    <div :class="$style.wrap" class="content">
      <div v-if="allowResize" ref="resizerEl" :class="$style.resizer" />
      <div :class="$style.header">
        <div :class="$style.headerIcon">
          <slot name="icon">
            <IconDocument class="w-6" />
          </slot>
        </div>

        <div ref="barEl" :class="[$style.headerTitle, headerBackground]">
          <div :class="$style.headerTitleText">
            {{ loading ? 'Loading...' : title }}
          </div>
        </div>

        <div
          v-if="allowMaximize"
          :class="$style.headerIcon"
          class="border-r border-gray-600"
        >
          <button :class="$style.headerButton" @click="maximize">
            <IconPxPlus class="w-6" />
          </button>
        </div>
        <div :class="$style.headerIcon" class="border-r">
          <button :class="$style.headerButton" @click="dismiss">
            <IconPxCross class="w-6" />
          </button>
        </div>
      </div>

      <div
        v-if="loading"
        class="flex justify-center items-center w-full h-full bg-white border border-black"
      >
        Loading...
      </div>

      <template v-else>
        <div v-if="$slots.actions" class="relative z-10">
          <slot name="actions" />
        </div>

        <div :class="[$style.content, contentClass]">
          <div
            class="h-full scrollbar"
            :class="[containerClass, { 'overflow-y-scroll': scrollY }]"
          >
            <slot />
          </div>
        </div>

        <div v-if="$slots.status" :class="$style.status">
          <slot name="status" />
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { mixin as windowMixin, emits as windowEmits } from '@/mixins/window';
import { defineComponent } from 'vue';
import IconPxCross from './icons/IconPxCross.vue';
import IconDocument from './icons/IconDocument.vue';
import IconPxPlus from './icons/IconPxPlus.vue';

export default defineComponent({
  components: {
    IconPxCross,
    IconDocument,
    IconPxPlus,
  },

  props: {
    allowResize: {
      type: Boolean,
      default: true,
    },
    allowMaximize: {
      type: Boolean,
      default: true,
    },
    scrollY: {
      type: Boolean,
      default: true,
    },
    containerClass: {
      type: String,
      default: 'p-2',
    },
    contentClass: {
      type: String,
      default: 'bg-white',
    },
    headerBackground: {
      type: [String, Array, Object],
      default: 'pattern-lines',
    },
    showBorders: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      default: 'basic',
    },
    x: {
      type: Number,
      required: true,
    },
    y: {
      type: Number,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
  },
  emits: windowEmits,
  setup(props, { emit }) {
    const { el, barEl, resizerEl, dismiss, focus, maximize } = windowMixin({
      startX: props.x,
      startY: props.y,
      startW: props.width,
      startH: props.height,
      emit,
    });

    return {
      el,
      barEl,
      resizerEl,
      dismiss,
      focus,
      maximize,
    };
  },
});
</script>

<style lang="postcss" module>
.wrap {
  @apply h-full flex flex-col border border-black shadow rounded w-full;
  padding: 2px;
}

.resizer {
  @apply absolute z-20 w-3 h-3 bottom-0 right-0 select-none cursor-nwse-resize;
  touch-action: none;
}

.header {
  @apply relative z-10 flex justify-between flex-shrink-0;
}

.headerIcon {
  @apply flex-shrink-0 flex items-center;
}

.headerTitle {
  @apply flex-grow flex justify-center cursor-move select-none;
  touch-action: none;
}

.headerTitleText {
  @apply px-2 uppercase;
}

.headerButton,
.headerButton:focus {
  @apply outline-none;
}

.content {
  @apply relative z-10 flex-grow overflow-hidden h-full;
}

.status {
  @apply relative z-10 flex flex-wrap justify-center items-center;
}

/* Basic */
.basic .wrap {
  @apply bg-gray-300;
}

.basic .header {
  @apply border-l border-r border-t border-black bg-white;
}

.basic .headerTitle {
  @apply border-r border-l border-black;
}

.basic .headerTitleText {
  @apply bg-white text-black;
}

.basic .headerButton {
  @apply text-black;
}

.basic .headerButton:focus,
.basic .headerButton:hover {
  @apply bg-black text-white;
}

.basic .content {
  @apply border border-black;
}

.basic .status {
  @apply border-l border-r border-b border-black bg-white;
}

/* Solid */
.solid .wrap {
  @apply bg-gray-300 text-black;
  --bg-opacity: 0.9;
}

.solid .header {
  @apply border-l border-r border-t border-black;
}
.solid .content {
  @apply border-l border-r border-b border-black;
}

.solid .headerButton:focus,
.solid .headerButton:hover {
  color: blue;
}
</style>
