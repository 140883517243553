import { Redirect } from '@/types/router';

export const redirects: Array<Redirect> = [
  {
    matcher: /^\/shop\/?$/,
    to: 'shop',
  },
  {
    matcher: /^\/shop\/(\S+)$/,
    to: 'product',
  },
  {
    matcher: /^\/lookbook\/?$/,
    to: 'lookbook',
  },
  {
    matcher: /^\/contact\/?$/,
    to: 'contact',
  },
  {
    matcher: /^\/about\/?$/,
    to: 'about',
  },
  {
    matcher: /^\/cart\/?$/,
    to: 'cart',
  },
];
