<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 16"
    fill="none"
    shape-rendering="crispEdges"
  >
    <g stroke="currentColor" stroke-width="2">
      <line x1="4" x2="20" y1="4" y2="4" />
      <line x1="4" x2="20" y1="8" y2="8" />
      <line x1="4" x2="20" y1="12" y2="12" />
    </g>
  </svg>
</template>
