import { defineAsyncComponent } from 'vue';
import About from '@/views/About.vue';
import Cart from '@/views/Cart.vue';
import Contact from '@/views/Contact.vue';
import Clock from '@/views/basic/Clock.vue';
import Credits from '@/views/basic/Credits.vue';
import Lookbook from '@/views/Lookbook.vue';
import Product from '@/views/Product.vue';
import Shop from '@/views/Shop.vue';
import { RouteDefinition } from '@/types/router';

export const routes: Array<RouteDefinition> = [
  {
    name: 'shop',
    component: Shop,
  },
  {
    name: 'lookbook',
    component: Lookbook,
  },
  {
    name: 'about',
    component: About,
  },
  {
    name: 'contact',
    component: Contact,
  },
  {
    name: 'product',
    component: Product,
  },
  {
    name: 'cart',
    component: Cart,
  },
  {
    name: 'credits',
    component: Credits,
  },
  {
    name: 'ticktock',
    component: Clock,
  },
  {
    name: 'baesh',
    component: defineAsyncComponent(
      () => import(/* webpackChunkName: "baesh" */ '@/views/Terminal.vue')
    ),
  },
];
