<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    shape-rendering="crispEdges"
  >
    <polygon
      points="6,4 13,4 18,9 18,20 6,20"
      stroke-width="1"
      fill="none"
      stroke="currentColor"
    />

    <polyline
      points="13,4 13,9 18,9"
      stroke-width="1"
      fill="none"
      stroke-linejoin="miter"
      stroke-miterlimit="2"
      stroke="currentColor"
    />
  </svg>
</template>
