
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from 'vue';
import { format } from 'date-fns';

function randomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export default defineComponent({
  props: {
    loaded: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['load'],
  setup(props, { emit }) {
    const data = reactive({
      version: '',
      platform: '',
    });
    const bootIndex = ref(0);
    const bootArr = ['/', '—', '\\', '|'];
    setInterval(() => {
      if (bootIndex.value >= bootArr.length - 1) {
        bootIndex.value = 0;
      } else {
        bootIndex.value += 1;
      }
    }, 100);
    const memory = ref(999999);
    const buildNumber = ref(9999);
    setInterval(() => {
      buildNumber.value = randomInt(1000, 9999);
      memory.value = randomInt(100000, 999999);
    }, 100);

    const now = new Date();
    const year = ref('');
    year.value = format(now, 'yyyy');
    const datetime = ref('');
    datetime.value = format(now, 'yyMMddHHmm zzzz');

    const boot = computed(() =>
      props.loaded ? ' ... SUCCESS!' : bootArr[bootIndex.value]
    );
    watch(
      () => props.loaded,
      () => {
        emit('load');
      }
    );
    onMounted(() => {
      data.version = navigator.appVersion;
      data.platform = navigator.platform;
    });
    return { boot, data, year, datetime, memory, buildNumber };
  },
});
