<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240.95 240.95">
    <path
      fill="#91d1bc"
      d="M128 15.06h-15.06V7.53H97.89v7.53h-7.53v7.53h-7.53v7.53H75.3v7.53h7.53v7.53h15.06v-7.53h15.05v-7.53h22.59v-7.53H128v-7.53z"
    />
    <path
      fill="#91d1bc"
      d="M90.36 52.71h-7.53v-7.53H75.3v-7.53h-7.53v-7.53H52.71v22.59H75.3v7.53h15.06v7.53h7.53v-7.53h7.52V45.18h-7.52v7.53h-7.53z"
    />
    <path
      fill="#91d1bc"
      d="M75.3 67.77v-7.53H52.71v-7.53H37.65v15.06h7.53v7.53h22.59v7.53h22.59v7.53h7.53V75.3h-7.53v-7.53H75.3z"
    />
    <path
      fill="#91d1bc"
      d="M75.3 90.36h-7.53v-7.53H45.18V75.3h-7.53v7.53h-7.53v7.53h7.53v7.53h15.06v7.53h37.65V90.36H75.3zM37.65 105.42v-7.53h-7.53v15.06h7.53v7.53h22.59v-7.53h-7.53v-7.53H37.65zM233.42 128.01h-7.53v-7.53h-30.12v7.53h-15.06v7.53h-7.53v7.53h-15.06v7.53h-15.06v7.53h22.59v7.53h15.06v7.53h7.53v7.53h15.06v7.52h7.53v7.53h15.06v7.54h7.53v7.52h7.53V128.01h-7.53z"
    />
    <path
      fill="#231f20"
      d="M143.06 7.53V0H128v7.53h15.06zM112.94 7.53V0H97.89v7.53h15.05zM143.06 7.53h7.53v7.53h-7.53zM120.47 15.06H128V7.53h-15.06v7.53h7.53zM90.36 7.53h7.53v7.53h-7.53zM158.12 15.06h-7.53v7.53h-15.06v7.53h30.12v-7.53h-7.53v-7.53zM128 15.06h7.53v7.53H128zM82.83 15.06h7.53v7.53h-7.53zM173.18 15.06h-7.53v7.53h15.06v-7.53h-7.53zM67.77 30.12v7.53h7.53v-7.53h7.53v-7.53H52.71v7.53h15.06zM120.47 30.12h-7.53v7.53h22.59v-7.53h-15.06zM188.24 30.12v-7.53h-7.53v15.06h-7.53v7.53h15.06V30.12zM97.89 37.65v7.53h7.52v15.06h-7.52v7.53h-7.53v7.53h7.53v15.06h7.52V67.77h7.53V37.65H97.89zM75.3 37.65h7.53v7.53H75.3z"
    />
    <path
      fill="#231f20"
      d="M90.36 52.71h7.53v-7.53H82.83v7.53h7.53zM52.71 52.71V30.12h-7.53v15.06h-7.53v7.53h15.06zM188.24 45.18h7.53v7.53h-7.53zM158.12 45.18v7.53h15.06v-7.53h-15.06zM143.06 52.71v7.53h15.06v-7.53h-15.06zM67.77 52.71H52.71v7.53H75.3v-7.53h-7.53zM37.65 52.71h-7.53v15.06h7.53V52.71zM82.83 60.24H75.3v7.53h15.06v-7.53h-7.53zM203.3 60.24v-7.53h-7.53v7.53h-7.53v7.53h7.53v7.53h7.53V60.24zM37.65 67.77h7.53v7.53h-7.53zM173.18 67.77v7.53h15.06v-7.53h-15.06zM52.71 82.83h15.06V75.3H45.18v7.53h7.53zM30.12 75.3h7.53v7.53h-7.53zM158.12 75.3v7.53h15.06V75.3h-15.06zM75.3 90.36h15.06v-7.53H67.77v7.53h7.53zM22.59 82.83h7.53v7.53h-7.53zM90.36 97.89v7.53H52.71v7.53h7.53v7.53H37.65v7.53h30.12v-15.06h30.12V90.36h-7.53v7.53zM30.12 90.36h7.53v7.53h-7.53zM45.18 97.89h-7.53v7.53h15.06v-7.53h-7.53zM173.18 97.89v7.53h15.06v-7.53h-15.06zM165.65 105.42h7.53v7.53h-7.53zM30.12 112.95V97.89h-7.53v15.06h-7.53v7.53h22.59v-7.53h-7.53zM218.36 112.95h-7.53V75.3h-7.53v15.06h-15.06v7.53h15.06v15.06h-7.53v7.53h30.12v-7.53h-7.53z"
    />
    <path
      fill="#231f20"
      d="M180.71 120.48v7.53h15.06v-7.53h-15.06zM173.18 128.01h7.53v7.53h-7.53zM158.12 135.54v7.53h15.06v-7.53h-15.06zM143.06 143.07v7.53h15.06v-7.53h-15.06zM128 150.6H97.89v7.53h45.17v-7.53H128zM150.59 165.66h15.06v-7.53h-22.59v7.53h7.53zM90.36 158.13h7.53v7.53h-7.53zM82.83 165.66h7.53v7.53h-7.53zM173.18 173.19h7.53v-7.53h-15.06v7.53h7.53zM67.77 173.19v7.53h15.06v-7.53H67.77zM180.71 173.19h7.53v7.53h-7.53zM233.42 128.01h7.53v-7.53h-15.06v7.53h7.53zM0 120.48v7.53h15.06v-7.53H0zM60.24 180.71h7.53v7.53h-7.53zM195.77 188.24h7.53v-7.52h-15.06v7.52h7.53zM45.18 188.24v7.53h15.06v-7.53H45.18zM203.3 188.24h7.53v7.53h-7.53zM37.65 195.77h7.53v7.53h-7.53zM218.36 203.31h7.53v-7.54h-15.06v7.54h7.53zM22.59 203.31v7.52h15.06v-7.52H22.59zM0 218.36v7.53h15.06v-7.53H0z"
    />
    <path
      fill="#231f20"
      d="M225.89 203.3h7.53v7.53h-7.53zM15.06 210.83h7.53v7.53h-7.53zM233.42 210.83h7.53v7.53h-7.53z"
    />
    <path
      fill="#fcd3c6"
      d="M135.53 22.59h15.06v-7.53h-7.53V7.53H128v7.53h7.53v7.53zM195.77 75.3v-7.53h-7.53v7.53h-15.06v7.53h-15.06V75.3h15.06v-7.53h15.06v-7.53h7.53v-7.53h-7.53v-7.53h-15.06v7.53h-15.06v7.53h-15.06v-7.53h15.06v-7.53h15.06v-7.53h7.53V22.59h-15.06v7.53h-30.12v7.53h-22.59v30.12h-7.53v22.59h-7.52v22.59H67.77v15.06H37.65v-7.53H15.06v7.53H0V218.36h15.06v-7.53h7.53v-7.52h15.06v-7.54h7.53v-7.53h15.06v-7.52h7.53v-7.53h15.06v-7.53h7.53v-7.53h7.53v-7.53h45.17v-7.53h15.06v-7.53h15.06v-7.53h7.53v-7.53h15.06v-7.53h7.53V97.89h-15.06v7.53h-15.06v7.53h-7.53v-7.53h7.53v-7.53h15.06v-7.53h15.06V75.3h-7.53z"
    />
  </svg>
</template>
