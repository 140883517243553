
import { computed, defineComponent, ref } from 'vue';
import { useStore } from '@/store';
import {
  props,
  components as viewComponents,
  emits,
  sizes,
} from '@/mixins/view';
import DesktopIcon from '@/components/DesktopIcon.vue';
import IconPxGrid from '@/components/icons/IconPxGrid.vue';
import IconPxList from '@/components/icons/IconPxList.vue';
import ProductsList from '@/components/ProductsList.vue';

export default defineComponent({
  components: {
    ...viewComponents,
    DesktopIcon,
    IconPxGrid,
    IconPxList,
    ProductsList,
  },
  props,
  emits,
  setup(props, { emit }) {
    const store = useStore();
    const layout = computed(() => store.state.shopLayout);
    // const layout = ref('grid');
    const searchInput = ref('');
    const switchLayout = (value: string) => {
      store.commit.shopLayoutSet(value);
    };

    const onDismiss = () => emit('dismiss');
    const products = computed(() => {
      const searchLower = searchInput.value.toLowerCase();
      return store.state.products
        ?.map((p) => ({
          id: p.id,
          image: p.image,
          title: p.title,
          type: p.type,
          link: { name: 'product', param: p.handle },
          variants: p.variants,
          searchStr: p.title.toLowerCase(),
        }))
        .filter((p) => p.searchStr.includes(searchLower));
    });

    return {
      layout,
      switchLayout,
      products,
      onDismiss,
      searchInput,
      sizes: sizes(),
    };
  },
});
