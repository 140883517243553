<template>
  <BaseWindow
    :title="title"
    :loading="loading"
    :container-class="product ? 'p-2 lg:p-4' : ''"
    v-bind="sizes"
    @dismiss="onDismiss"
  >
    <template v-if="product" #status>
      <div class="w-full px-1 py-2 flex">
        <InputSelect
          v-model="selectedVariantId"
          class="mx-1 flex-grow"
          :options="variants"
        />
        <button
          class="btn mx-1"
          :disabled="!selectedVariant?.availableForSale"
          @click.prevent="addToCart"
        >
          {{ isSoldOut ? 'Sold Out' : 'Add to Cart' }}
        </button>
      </div>
    </template>
    <div v-if="product" class="flex flex-wrap -mx-2">
      <div class="w-full sm:w-1/2 px-2 sm:order-2">
        <div class="sticky top-0 leading-none mb-4">
          <div class="text-2xl sm:text-3xl">{{ product.title }}</div>
          <div class="mt-2 text-lg sm:text-xl">{{ price }}</div>
          <div class="mt-4 text-content" v-html="product.descriptionHtml" />
          <SizeChart class="mt-4 text-sm max-w-lg" :tags="product.tags" />
        </div>
      </div>
      <div class="w-full sm:w-1/2 px-2 sm:order-1">
        <a
          v-for="(image, i) of images"
          :key="image.id"
          :href="`#img-${i + 1}`"
          @click.prevent="onClickImage(image)"
        >
          <img
            :src="image.transformedSrc"
            :class="i === images.length - 1 ? '' : 'mb-2 lg:mb-4'"
            :alt="image.altText"
          />
        </a>
      </div>
    </div>
    <BaseException v-else component="button" @click.prevent="onDismiss">
      <p class="max-w-lg text-left mt-4">
        NotFound: the product you requested could not be found. It may have been
        discontinued or maybe you can’t spell good.
      </p>
      <p class="max-w-lg mt-4">Click here please</p>
    </BaseException>
    <teleport to="#based">
      <transition
        name="flash"
        duration="250"
        @after-enter="notificationAddListeners"
      >
        <BaseNotification
          v-if="notification"
          :background="notification === 'error' ? 'bg-danger' : 'bg-ms'"
          @dismiss="notificationRemoveListeners"
        >
          <template v-if="notification === 'error'">
            Gamesmaster: You can only have 10 of each item in
            <BasedLink :to="{ name: 'cart' }" class="uppercase underline"
              >the cart</BasedLink
            >!
          </template>
          <template v-else>
            Gamemaster: {{ product.title }} ({{ selectedVariant.title }}) has
            been added to
            <BasedLink :to="{ name: 'cart' }" class="uppercase underline"
              >the cart</BasedLink
            >!
          </template>
        </BaseNotification>
      </transition>
    </teleport>
  </BaseWindow>
</template>

<script lang="ts">
import { computed, defineComponent, markRaw, nextTick, ref, watch } from 'vue';
import { useQuery, useResult } from '@vue/apollo-composable';
import { useRouter } from '@/router';
import { useStore } from '@/store';
import ProductQuery from '@/graphql/queries/Product.gql';
import Image from '@/views/basic/Image.vue';
import SizeChart from '@/components/SizeChart.vue';
import BaseException from '@/components/BaseException.vue';
import BaseNotification from '@/components/BaseNotification.vue';
import InputSelect from '@/components/InputSelect.vue';
import {
  props,
  components as viewComponents,
  emits,
  sizes,
} from '@/mixins/view';
import { getNodes, setDefaultVariantTitle } from '@/util/transformers';
import { formatPrice } from '@/util/helpers';
import { notification as notificationMixin } from '@/mixins/notification';
import { ResponseProduct } from '@/types/responses';
import { ShopifyProductImage } from '@/types/product';
import { trackViewItem } from '@/plugins/gtag';

export default defineComponent({
  components: {
    BaseException,
    BaseNotification,
    InputSelect,
    SizeChart,
    ...viewComponents,
  },
  props,
  emits,
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();
    const { result, loading, onResult } = useQuery<ResponseProduct>(
      ProductQuery,
      {
        handle: props.view?.param,
      }
    );

    onResult((res: { data: ResponseProduct }) => {
      const product = res.data.productByHandle;
      if (product) {
        trackViewItem({
          item_id: product.id,
          item_name: product.title,
        });
      }
    });

    const {
      notification,
      notificationAddListeners,
      notificationRemoveListeners,
    } = notificationMixin();
    // Product properties
    const title = useResult(
      result,
      'Not Found',
      (data) => data.productByHandle.title
    );
    const product = useResult(result, null, (data) => data.productByHandle);
    const variants = useResult(result, [], (data) =>
      getNodes(data.productByHandle.variants).map((variant) => ({
        ...setDefaultVariantTitle(variant),
        disabled: !variant.availableForSale,
      }))
    );
    const isSoldOut = computed(() => {
      return variants.value.every((variant) => !variant.availableForSale);
    });
    const price = useResult(result, '', (data) => {
      const {
        minVariantPrice,
        maxVariantPrice,
      } = data.productByHandle.priceRange;
      const prices = [minVariantPrice, maxVariantPrice]
        .map((price) => parseFloat(price.amount))
        .filter((amount, index, self) => self.indexOf(amount) === index)
        .map(formatPrice)
        .join(' – ');
      return prices;
    });
    const images = useResult(result, [], (data) =>
      getNodes(data.productByHandle.images)
    );

    const selectedVariantId = ref('');
    const selectedVariant = computed(() =>
      variants.value.find((variant) => variant.id === selectedVariantId.value)
    );

    // Methods
    const addToCart = () => {
      const p = product.value;
      const v = selectedVariant.value;
      if (p && v?.availableForSale) {
        const added = store.commit.cartAdd(p, v);
        notification.value = false;
        nextTick(() => {
          const audio = new Audio('alert-quiet.mp3');
          audio.play();
          notification.value = added ? true : 'error';
        });
      }
    };

    const onDismiss = () => emit('dismiss');

    const onClickImage = (image: ShopifyProductImage) => {
      router.pushWindow({
        id: image.id,
        component: markRaw(Image),
        props: {
          id: image.id,
          src: image.transformedSrc,
          alt: image.altText,
        },
      });
    };

    return {
      title,
      product,
      variants,
      images,
      price,
      result,
      isSoldOut,
      loading,
      onDismiss,
      onClickImage,
      addToCart,
      selectedVariantId,
      selectedVariant,
      notification,
      notificationAddListeners,
      notificationRemoveListeners,
      sizes: sizes(),
    };
  },
});
</script>
