<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
    <g fill="currentColor">
      <rect x="1" y="1" width="4" height="2" />
      <rect x="3" y="1" width="2" height="4" />
      <rect x="3" y="4" width="11" height="7" />
      <rect x="4" y="12" width="3" height="3" />
      <rect x="10" y="12" width="3" height="3" />
    </g>
  </svg>
</template>
