
import { ref, computed, defineComponent } from 'vue';
import { format } from 'date-fns';
import { useQuery, useResult } from '@vue/apollo-composable';
import PageQuery from '@/graphql/queries/Page.gql';
import { useRoute } from '@/router';
import BaseMarquee from '@/components/BaseMarquee.vue';
import IconPxCart from '@/components/icons/IconPxCart.vue';
import IconPxClock from '@/components/icons/IconPxClock.vue';
import TaskBarMenu from '@/components/TaskBarMenu.vue';

export default defineComponent({
  components: {
    BaseMarquee,
    IconPxCart,
    IconPxClock,
    TaskBarMenu,
  },
  props: {
    cartCount: {
      type: Number,
      required: true,
    },
  },
  emits: ['shutdown'],
  setup(props, { emit }) {
    const route = useRoute();
    // Views
    const views = computed(() => route.views);
    // Time
    const time = ref('');
    const updateFormattedDateTime = () => {
      const now = new Date();
      time.value = format(now, 'HH:mm:ss');
    };
    updateFormattedDateTime();
    setInterval(updateFormattedDateTime, 1000);
    // Marquee
    const { result } = useQuery(PageQuery, {
      handle: 'marquee',
    });
    const marqueeContent = useResult(
      result,
      '',
      (data) => data.pageByHandle.bodySummary
    );
    // Menu
    const menuVisible = ref(false);
    const menuShow = () => {
      menuVisible.value = true;
    };
    const menuHide = () => {
      menuVisible.value = false;
    };
    const onShutdown = () => {
      emit('shutdown');
    };
    return {
      views,
      time,
      marqueeContent,
      menuVisible,
      menuShow,
      menuHide,
      onShutdown,
    };
  },
});
