import { defineComponent, h, PropType } from 'vue';
import { useRouter } from '@/router';
import { wrapper } from '@/router/wrapper';
import { RouterView } from '@/types/router';

export const BasedView = defineComponent({
  name: 'BasedView',
  props: {
    view: {
      type: Object as PropType<RouterView>,
      required: true,
    },
  },
  setup(props) {
    const key = props.view.name + (props.view.param || '');
    const { el, setActive, onWrapperMount } = wrapper(key);
    const router = useRouter();
    const onDismiss = () => {
      const { name, param } = props.view;
      router.pull({ name, param });
    };
    return () =>
      h(
        'div',
        { key, ref: el },
        // @ts-expect-error typing components
        h(props.view.component, {
          view: props.view,
          onFocus: setActive,
          onMounted: onWrapperMount,
          onDismiss,
        })
      );
  },
});
