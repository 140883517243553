<template>
  <div
    class="block flex-grow flex-shrink min-w-0 overflow-y-scroll whitespace-no-wrap text-left overflow-hidden"
  >
    <div
      :class="{ [$style.content]: content }"
      class="inline-block align-top h-full overflow-hidden will-change-transform"
    >
      {{ content }}
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    content: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="postcss" module>
.content {
  padding-left: 100%;
  animation: marquee 25s linear infinite;
  line-height: 2rem;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
