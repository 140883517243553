
import { defineComponent, onBeforeUnmount } from 'vue';

export default defineComponent({
  props: {
    text: {
      type: String,
      default: '',
    },
    background: {
      type: String,
      default: 'bg-ms',
    },
  },
  emits: ['dismiss'],
  setup(props, { emit }) {
    onBeforeUnmount(() => {
      emit('dismiss');
    });
  },
});
