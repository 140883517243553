<template>
  <transition-group
    name="icon-fade"
    tag="menu"
    class="relative h-full w-full z-1 p-4 flex flex-col flex-wrap content-start m-0"
    @click.right.self.prevent="onRightClick"
    @after-enter="afterEnter"
  >
    <DesktopIcon
      v-if="iconCount > 0"
      class="m-4"
      text="Shop"
      :to="{ name: 'shop' }"
    >
      <IconShop class="w-full" />
    </DesktopIcon>

    <DesktopIcon
      v-if="iconCount > 1"
      class="m-4"
      text="Lookbook"
      :to="{ name: 'lookbook' }"
    >
      <IconLookbook class="w-full" />
    </DesktopIcon>

    <DesktopIcon
      v-if="iconCount > 2"
      class="m-4"
      text="About"
      :to="{ name: 'about' }"
    >
      <IconAbout class="w-full" />
    </DesktopIcon>

    <DesktopIcon
      v-if="iconCount > 3"
      class="m-4"
      text="Contact"
      :to="{ name: 'contact' }"
    >
      <IconContact class="w-full" />
    </DesktopIcon>
    <DesktopIcon
      v-if="iconCount > 4"
      class="m-4 relative"
      text="Cart"
      :to="{ name: 'cart' }"
    >
      <IconCart class="w-full" />
      <div
        v-if="cartCount"
        class="absolute top-0 right-0 bg-danger text-white leading-none rounded-full w-5 h-5 text-xs flex justify-center items-center"
      >
        {{ cartCount }}
      </div>
    </DesktopIcon>
  </transition-group>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import DesktopIcon from '@/components/DesktopIcon.vue';
import IconShop from '@/components/icons/IconShop.vue';
import IconLookbook from '@/components/icons/IconLookbook.vue';
import IconAbout from '@/components/icons/IconAbout.vue';
import IconContact from '@/components/icons/IconContact.vue';
import IconCart from '@/components/icons/IconCart.vue';
import { useRouter } from '@/router';
import { useStore } from '@/store';
import { getEventClientPos } from '@/util/event-pos';

export default defineComponent({
  components: {
    DesktopIcon,
    IconShop,
    IconLookbook,
    IconAbout,
    IconContact,
    IconCart,
  },
  props: {
    cartCount: {
      type: Number,
      required: true,
    },
  },
  emits: ['rightclick'],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const iconCount = ref(0);
    watch(
      () => store.state.initialized,
      (initialized) => {
        if (initialized) {
          iconCount.value += 1;
        } else {
          iconCount.value = 0;
        }
      }
    );
    // Transitions
    const afterEnter = () => {
      iconCount.value += 1;
    };
    const openView = (name: string) => {
      router.push({ name });
    };

    // Menu
    const onRightClick = (e: MouseEvent | TouchEvent) => {
      emit('rightclick', getEventClientPos(e));
    };
    return {
      afterEnter,
      iconCount,
      onRightClick,
      openView,
    };
  },
});
</script>
