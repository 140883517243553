
import { defineComponent } from 'vue';
import { useQuery, useResult } from '@vue/apollo-composable';
import PageQuery from '@/graphql/queries/Page.gql';
import { sizes, props, components, emits } from '@/mixins/view';

export default defineComponent({
  components,
  props,
  emits,
  setup(props, { emit }) {
    const onDismiss = () => emit('dismiss');
    const { result, loading } = useQuery(PageQuery, {
      handle: 'about',
    });
    const title = useResult(result, '', data => data.pageByHandle.title);
    const body = useResult(result, null, data => data.pageByHandle.body);
    return { title, body, loading, onDismiss, sizes: sizes([0.4, 0.8]) };
  },
});
