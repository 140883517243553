<template>
  <table :class="$style.cart" class="table-auto w-full">
    <thead>
      <tr>
        <th>Item</th>
        <th class="w-1/12">Qty</th>
        <th class="hidden lg:table-cell w-3/12 sm:w-2/12 lg:w-1/12">Price</th>
        <th class="w-3/12 sm:w-2/12 lg:w-1/12">Total</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item of items" :key="`${item.productId}-${item.variantId}`">
        <td>
          <BasedLink
            :to="{ name: 'product', param: item.productHandle }"
            class="hover:underline focus:underline"
          >
            {{ item.productTitle }}
          </BasedLink>
          <br />
          <span class="text-xs">
            {{ item.variantTitle }}
          </span>
        </td>
        <td>
          <select
            :value="item.quantity"
            @change="(e) => updateQuantity(e, item.variantId)"
          >
            <option v-for="i of 11" :key="i - 1" :value="i - 1">
              {{ i - 1 }}
            </option>
          </select>
        </td>
        <td class="hidden lg:table-cell">£{{ item.price.toFixed(2) }}</td>
        <td>£{{ (item.price * item.quantity).toFixed(2) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { CartItem } from '@/types/product';

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<CartItem[]>,
      required: true,
    },
  },
  emits: ['update:quantity', 'remove'],
  setup(props, { emit }) {
    const removeItem = (variantId: string) => {
      emit('remove', variantId);
    };
    const updateQuantity = (e: Event, variantId: string) => {
      const target = e.target as HTMLSelectElement;
      emit('update:quantity', {
        variantId,
        quantity: parseInt(target.value, 10),
      });
    };
    return { updateQuantity, removeItem };
  },
});
</script>

<style lang="postcss" module>
.cart th,
.cart td {
  @apply leading-none text-left px-2 py-1;
}

.cart th {
  @apply font-normal border-b border-r border-black bg-gray-200 whitespace-no-wrap;
}

.cart th:last-child {
  @apply border-r-0;
}

.cart td {
  @apply border-b border-dotted border-gray-500;
}

.cart td:last-child {
  @apply p-0;
}
</style>
