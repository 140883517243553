import { defineComponent, h, reactive, computed, unref, PropType } from 'vue';
import { useRouter } from '@/router';
import { RouteLocationRaw, RouterLink } from '@/types/router';

function guardEvent(e: MouseEvent) {
  if (e.defaultPrevented) return;
  if (e.button !== undefined && e.button !== 0) return;
  if (e.preventDefault) e.preventDefault();
  return true;
}

function useLink(to: RouteLocationRaw): RouterLink {
  const router = useRouter();
  const href = computed(() => router.resolve(unref(to)));

  function navigate() {
    router.push(unref(to));
  }

  return {
    href,
    navigate,
  };
}

export const BasedLink = defineComponent({
  name: 'BasedLink',
  props: {
    to: {
      type: [Object, String] as PropType<RouteLocationRaw | string>,
      required: true,
    },
    preventNavigation: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  setup(props, { slots, attrs, emit }) {
    if (typeof props.to === 'string') {
      const href = props.to as string;
      const onClick = (e: MouseEvent) => {
        if (props.preventNavigation) {
          e.preventDefault();
        }
        emit('click', e);
      };
      return () => {
        const children = slots.default && slots.default();
        return h(
          'a',
          {
            href,
            onClick,
            target: '_blank',
            ...attrs,
          },
          children
        );
      };
    }
    const link = reactive(useLink(props.to));
    const onClick = (e: MouseEvent) => {
      if (guardEvent(e) && !props.preventNavigation) {
        link.navigate();
      }
      emit('click', e);
    };
    return () => {
      const children = slots.default && slots.default(link);
      return h(
        'a',
        {
          href: link.href,
          onClick,
          ...attrs,
        },
        children
      );
    };
  },
});
