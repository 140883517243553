<template>
  <BaseWindow
    title="TickTock"
    :scroll-y="false"
    theme="solid"
    header-background=""
    content-class=""
    v-bind="sizes"
    :allow-resize="false"
    :allow-maximize="false"
    @dismiss="onDismiss"
  >
    <div class="flex flex-col h-full justify-between items-center">
      <div class="clock">
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="100%"
          height="100%"
          viewBox="0 0 100 100"
          class="text-gray-800"
        >
          <line
            v-for="i of 60"
            :key="i"
            :stroke-width="i % 5 ? 1 : 2"
            stroke="currentColor"
            :transform="`rotate(${i * 6}, 50, 50)`"
            x1="50"
            :y1="i % 5 ? 4 : 8"
            x2="50"
            y2="0"
          />
        </svg>
        <div class="hours-container">
          <div class="hours" :style="{ transform: `rotateZ(${hours}deg)` }" />
        </div>
        <div
          class="minutes-container"
          :style="{ transform: `rotateZ(${minutes}deg)` }"
        >
          <div class="minutes" />
        </div>
        <div
          class="seconds-container"
          :style="{ transform: `rotateZ(${seconds}deg)` }"
        >
          <div class="seconds" />
        </div>
      </div>
      <div class="leading-none text-center">{{ timezoneStr }}</div>
    </div>
  </BaseWindow>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { format } from 'date-fns';
import { props, components, emits, sizesFixed } from '@/mixins/view';

export default defineComponent({
  components,
  props,
  emits,
  setup(props, { emit }) {
    let timezoneStr = '';
    const onDismiss = () => emit('dismiss');

    const hours = ref(0);
    const minutes = ref(0);
    const seconds = ref(0);

    const getTimeZoneStr = (date: Date) => {
      const parts = [];
      parts.push(new window.Intl.DateTimeFormat().resolvedOptions().timeZone);
      parts.push(format(date, 'zzzz'));
      return parts.filter(a => a).join(' ');
    };

    const setTime = () => {
      const date = new Date();
      timezoneStr = getTimeZoneStr(date);
      const s = date.getSeconds();
      const m = date.getMinutes();
      const h = date.getHours();
      seconds.value = s * 6;
      minutes.value = m * 6;
      hours.value = h * 30 + m / 2;
    };

    setTime();

    setInterval(() => {
      seconds.value += 6;
      if (seconds.value % 360 === 0) {
        minutes.value += 6;
      }
    }, 1000);

    return {
      sizes: sizesFixed(300, 400),
      onDismiss,
      seconds,
      minutes,
      hours,
      timezoneStr,
    };
  },
});
</script>

<style lang="postcss" scoped>
.clock {
  @apply relative;
  width: 280px;
  height: 280px;
}

.clock::after {
  @apply rounded-full absolute bg-black;
  width: 4%;
  height: 4%;
  content: '';
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}

.minutes-container,
.hours-container,
.seconds-container {
  @apply absolute inset-0;
}

.hours-container {
  animation: rotate 43200s infinite linear;
}

.minutes-container {
  transition: transform 0.3s cubic-bezier(0.4, 2, 0.5, 0.4);
}
.seconds-container {
  transition: transform 0.3s cubic-bezier(0.4, 2, 0.5, 0.4);
}

.hours,
.minutes,
.seconds {
  @apply absolute;
}

.hours {
  @apply bg-based;
  height: 45%;
  left: 49%;
  top: 15%;
  transform-origin: 50% 77.5%;
  width: 2%;
}

.minutes {
  @apply bg-based;
  width: 2%;
  height: 58%;
  left: 49%;
  top: 2%;
}

.seconds {
  @apply bg-black;
  height: 55%;
  width: 1%;
  top: 7.5%;
  left: 49.5%;
  z-index: 8;
}

@keyframes rotate {
  100% {
    transform: rotateZ(360deg);
  }
}
</style>
