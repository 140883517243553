
import { defineComponent, PropType, ref } from 'vue';

const charts = [
  {
    title: 'Short Sleeve T-Shirt',
    name: 'sizing:tshirtss',
    rows: [
      ['Size', 'XS', 'S', 'M', 'L', 'XL', 'XXL'],
      ['Chest', '51', '53', '55', '57', '60', '63'],
      ['Length', '66', '68', '71', '73', '76', '79'],
      ['Sleeve', '20.5', '22.5', '24.5', '25.5', '26.5', '27.5'],
    ],
  },
  {
    title: 'Long Sleeve T-Shirt',
    name: 'sizing:tshirtls',
    rows: [
      ['Size', 'XS', 'S', 'M', 'L', 'XL', 'XXL'],
      ['Chest', '51', '53', '55', '57', '60', '63'],
      ['Length', '66', '68', '71', '73', '76', '79'],
      ['Sleeve', '63', '64', '65', '66', '68', '70'],
    ],
  },
  {
    title: 'Hoodie',
    name: 'sizing:hoodie',
    rows: [
      ['Size', 'XS', 'S', 'M', 'L', 'XL', 'XXL'],
      ['Chest', '53', '56', '59', '62', '65', '67'],
      ['Length', '66', '68', '70', '72', '74', '76'],
      ['Sleeve', '60', '61', '62', '63', '64', '65'],
    ],
  },
  {
    title: 'Pullover',
    name: 'sizing:pullover',
    rows: [
      ['Size', 'XS', 'S', 'M', 'L', 'XL', 'XXL'],
      ['Chest', '55', '58', '61', '64', '67', '70'],
      ['Length', '72', '74', '76', '78', '80', '82'],
      ['Sleeve', '63', '64', '65', '66', '67', '68'],
    ],
  },
  {
    title: 'Grid Runners',
    name: 'sizing:gridrunners',
    rows: [
      ['Size', 'XS', 'S', 'M', 'L', 'XL', 'XXL'],
      ['Waist', '66', '70', '74', '78', '82', '86'],
      ['Outside Length', '97', '99', '99', '102', '102', '102'],
    ],
  },
  {
    title: 'Schema Shorts',
    name: 'sizing:schemashorts',
    rows: [
      ['Size', 'XS', 'S', 'M', 'L', 'XL', 'XXL'],
      ['1/2 Waistband Width', '35', '37', '39', '41', '43', '45'],
      ['Length', '45.5', '47', '48.5', '50', '51.5', '53'],
      ['Hem', '27', '28.5', '30', '31.5', '33', '34.5'],
    ],
  },
  {
    title: 'Grid Runner Top',
    name: 'sizing:gridrunnertop',
    rows: [
      ['Size', 'XS', 'S', 'M', 'L', 'XL', 'XXL'],
      ['Chest', '58', '61', '64', '67', '70', '73'],
      ['Length', '68', '70', '72', '74', '76', '78'],
      ['Sleeve', '66', '67', '68', '69', '70', '71'],
    ],
  },
  {
    title: 'Hoodie Tier 2',
    name: 'sizing:hoodiestier2',
    rows: [
      ['Size', 'XS', 'S', 'M', 'L', 'XL', 'XXL'],
      ['Chest', '56', '59', '62', '65', '68', '71'],
      ['Length', '66', '68', '70', '72', '74', '76'],
      ['Sleeve', '58', '59', '60', '61', '62', '63'],
    ],
  },
  {
    title: 'R34CH Jacket',
    name: 'sizing:reachjacket',
    rows: [
      ['Size', 'XS', 'S', 'M', 'L', 'XL', 'XXL'],
      ['Chest', '57', '60', '63', '66', '69', '72'],
      ['Length', '68', '70', '72', '74', '76', '78'],
      ['Sleeve', '64', '65', '66', '67', '68', '69'],
    ],
  },
  {
    title: 'Grid Runner Pants',
    name: 'sizing:gridrunnerpants',
    rows: [
      ['Size', 'XS', 'S', 'M', 'L', 'XL', 'XXL'],
      ['1/2 Waistband Width', '33', '35', '37', '39', '41', '43'],
      ['Length', '105', '107', '109', '111', '113', '115'],
      ['1/2 Knee', '25.5', '26.5', '27.5', '28.5', '29.5', '30.5'],
    ],
  },
];

export default defineComponent({
  props: {
    tags: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
  },
  setup(props) {
    const chart = charts.find((chart) => props.tags.includes(chart.name));
    const isVisible = ref(false);
    const toggle = () => {
      isVisible.value = !isVisible.value;
    };
    return { chart, isVisible, toggle };
  },
});
