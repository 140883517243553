
import { defineComponent, PropType } from 'vue';
import { CartItem } from '@/types/product';

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<CartItem[]>,
      required: true,
    },
  },
  emits: ['update:quantity', 'remove'],
  setup(props, { emit }) {
    const removeItem = (variantId: string) => {
      emit('remove', variantId);
    };
    const updateQuantity = (e: Event, variantId: string) => {
      const target = e.target as HTMLSelectElement;
      emit('update:quantity', {
        variantId,
        quantity: parseInt(target.value, 10),
      });
    };
    return { updateQuantity, removeItem };
  },
});
