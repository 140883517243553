<template>
  <div :class="$style.menu" ref="root">
    <div :class="$style.text">
      Based <span class="text-xs">Big Brain Edition</span>
    </div>
    <div :class="$style.textBackground"></div>
    <div class="leading-none py-1">
      <BasedLink
        v-for="link of links"
        :key="link.name"
        :to="{ name: link.to }"
        :class="$style.item"
        @click="dismiss"
        ><component :is="link.component" :class="$style.itemIcon" />
        {{ link.title }}</BasedLink
      >
      <div class="bg-gray-500 w-full h-px my-1" />
      <BasedLink
        :to="{ name: 'credits' }"
        :class="$style.item"
        class="text-sm"
        @click="dismiss"
      >
        <div class="w-8 h-5 mr-2"></div>
        Credits</BasedLink
      >
      <button :class="$style.item" class="text-sm" @click="shutdown">
        <div class="w-8 h-5 mr-2"></div>
        Shut Down
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
import IconShop from '@/components/icons/IconShop.vue';
import IconLookbook from '@/components/icons/IconLookbook.vue';
import IconAbout from '@/components/icons/IconAbout.vue';
import IconContact from '@/components/icons/IconContact.vue';
import IconCart from '@/components/icons/IconCart.vue';
import IconShell from '@/components/icons/IconShell2.vue';

export default defineComponent({
  components: {
    IconShop,
    IconLookbook,
    IconAbout,
    IconContact,
    IconCart,
    IconShell,
  },
  emits: ['dismiss', 'shutdown'],
  setup(props, { emit }) {
    const links = ref([
      { to: 'shop', title: 'Shop', component: 'IconShop' },
      { to: 'lookbook', title: 'Lookbook', component: 'IconLookbook' },
      { to: 'about', title: 'About', component: 'IconAbout' },
      { to: 'contact', title: 'Contact', component: 'IconContact' },
      { to: 'cart', title: 'Cart', component: 'IconCart' },
      { to: 'baesh', title: 'Baesh', component: 'IconShell' },
    ]);
    const root = ref<HTMLElement | null>(null);

    const dismiss = () => {
      emit('dismiss');
    };

    const onKeyUp = (e: KeyboardEvent) => {
      const key = e.key || e.keyCode;
      if (key === 'Escape' || key === 'Esc' || key === 27) {
        dismiss();
      }
    };
    const onOutsideClick = (e: MouseEvent | TouchEvent) => {
      if (
        root.value &&
        e.target instanceof HTMLElement &&
        !root.value.contains(e.target)
      ) {
        dismiss();
      }
    };
    onMounted(async () => {
      // Timeout to prevent insta click
      setTimeout(() => {
        window.addEventListener('click', onOutsideClick);
        window.addEventListener('keyup', onKeyUp);
      }, 100);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('click', onOutsideClick);
      window.removeEventListener('keyup', onKeyUp);
    });
    const shutdown = () => {
      emit('dismiss');
      emit('shutdown');
    };
    return { dismiss, links, root, shutdown };
  },
});
</script>

<style lang="postcss" module>
.menu {
  @apply absolute flex;
  bottom: 100%;
  @apply bg-gray-300 border-t border-r border-gray-700;
}

.textBackground {
  @apply w-6 bg-gray-500;
  background: linear-gradient(#bdbdbd, #48a39c);
}

.text {
  @apply absolute bottom-0 left-0 pl-2 h-6 origin-bottom-left text-white uppercase tracking-wide whitespace-no-wrap;
  transform: rotate(-90deg) translateY(100%);
}

.item {
  @apply flex items-center px-2 py-1;
}

.item:hover,
.item:focus {
  @apply bg-based text-white;
}

.itemIcon {
  @apply w-8 mr-2;
}
</style>
