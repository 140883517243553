import { defineComponent, h, PropType } from 'vue';
import { wrapper } from '@/router/wrapper';
import { RouterWindow } from '@/types/router';

export const BasedWindowWrapper = defineComponent({
  name: 'BasedWindowWrapper',
  props: {
    view: {
      type: Object as PropType<RouterWindow>,
      required: true,
    },
  },
  setup(props) {
    const { el, setActive, onWrapperMount } = wrapper(props.view.id);
    return () =>
      h(
        'div',
        { key: props.view.id, ref: el },
        // @ts-expect-error typing components
        h(props.view.component, {
          view: props.view,
          onFocus: setActive,
          onMounted: onWrapperMount,
          ...props.view.props,
        })
      );
  },
});
