<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 907 907">
    <path
      fill="#00A79D"
      d="M878.704 85.036H170.109v28.341H311.826v85.028H170.109v28.348H311.826v85.031H198.449v28.348h113.377v85.03h-85.032v28.341h85.032v56.688h28.344v-56.688h170.064v28.343h28.347v-28.343H850.36v-28.341h28.347V283.439H850.36v28.345h-85.03v-85.03H878.707v56.685h28.343V85.036h-28.346zm-311.78 28.341H736.985v85.028H538.58v-85.028h28.344zM340.17 170.066v-56.688h170.064v85.028H340.17v-28.34zm0 113.373v-56.685h170.064v85.03H340.17v-28.345zm141.718 141.722H340.17v-85.03h170.064v85.03h-28.346zm226.753 0H538.58v-85.03h198.404v85.03h-28.343zm85.036-85.03h56.681v56.686h-28.341v28.344h-56.689v-85.03h28.349zM736.983 255.1v56.684H538.58v-85.03h198.404V255.1zm141.721-113.382v56.688H765.327v-85.028H878.704v28.34z"
    />
    <g fill="#77CABC">
      <path
        d="M453.544 113.374H340.17v28.347h141.718v56.685h28.346V113.374h-28.346zM255.139 113.374h-85.03v28.347h113.374v56.685h28.343V113.374h-28.343zM850.357 113.374h-85.029v28.347h85.029v56.685h28.347V113.374zM680.296 113.374H538.581v28.347h170.06v56.685h28.342V113.374h-28.342zM453.544 226.754H340.17V255.1h141.718v56.684h28.346v-85.03h-28.346zM255.139 226.754h-56.69V255.1H283.483v56.684h28.343v-85.03h-28.343zM822.017 226.754h-56.689V255.1h85.029v28.339h28.347v-56.685h-28.347zM680.296 226.754H538.581V255.1h170.06v56.684h28.342v-85.03h-28.342zM453.544 340.131H340.17v28.341h141.718v56.689h28.346v-85.03h-28.346zM255.139 340.131h-56.69v28.341H283.483v56.689h28.343v-85.03h-28.343zM793.677 340.131h-28.349v28.341h56.689v28.345h28.34v-56.686h-28.34zM680.296 340.131H538.581v28.341h170.06v56.689h28.342v-85.03h-28.342zM453.544 453.502H340.17v28.343h170.064v-28.343h-28.346zM255.139 453.502h-28.345v28.343h56.689v28.345h28.343v-56.688h-28.343zM651.951 453.502h-113.37v28.343h170.06v-28.343h-28.345z"
      />
    </g>
    <g fill="#BCBEC0">
      <path
        d="M793.677 510.19H623.61v28.344H368.515v28.345H623.61v-28.341h198.407V510.19zM311.826 566.879H198.449v28.343h170.066v-28.343H340.17z"
      />
    </g>
    <g fill="#BCBEC0">
      <path
        d="M113.416 141.718V56.689H28.387v28.347h56.689v85.03h28.34v-28.345zM141.761 283.439V170.066h-28.345V340.131h28.345v-28.347zM141.761 340.131v198.403h-28.345v28.345h28.345v-28.341h28.348V340.131zM85.076 595.222v56.686h28.34v-85.029h-28.34zM113.416 651.908h28.345v28.349h-28.345z"
      />
    </g>
    <path
      d="M822.017 453.502H708.641v28.343H481.888v28.345H226.794V396.817h-28.345V226.754h-28.34V28.345h-28.348V0H28.387v56.689h85.029v113.377h28.345V340.131h28.348v198.403h-28.348v28.345h-28.345v85.029h28.345v28.348H822.017v-56.692H396.855v28.344H170.109v-56.686h28.34v-28.343h170.066v-28.341H623.61V510.19h226.747v-56.688z"
    />
    <path
      fill="#BCBEC0"
      d="M226.794 736.94h28.345v28.346h-28.345zM736.983 736.94h28.345v28.346h-28.345zM255.139 765.286h28.344v28.344h-28.344z"
    />
    <path fill="#D1D3D4" d="M226.794 765.286h28.345v28.344h-28.345z" />
    <path
      fill="#BCBEC0"
      d="M198.453 765.286h28.341v28.344h-28.341zM765.328 765.286h28.349v28.344h-28.349z"
    />
    <path fill="#D1D3D4" d="M736.983 765.286h28.345v28.344h-28.345z" />
    <path
      fill="#BCBEC0"
      d="M708.641 765.286h28.343v28.344h-28.343zM226.794 793.629h28.345v28.343h-28.345z"
    />
    <path fill="#BCBEC0" d="M736.983 793.629h28.345v28.343h-28.345z" />
    <path
      d="M283.483 736.94v-28.345h-85.03v28.345h-28.344v85.033h28.344v28.346h85.03v-28.346h28.343V736.94h-28.343zm0 56.689h-28.344v28.341h-28.345v-28.341h-28.341v-28.344h28.341V736.94h28.345v28.346h28.344v28.343zM793.677 736.94v-28.345H708.64v28.345h-28.345v85.033h28.345v28.346h85.037v-28.346h28.34V736.94h-28.34zm0 56.689h-28.349v28.341h-28.345v-28.341H708.64v-28.344h28.343V736.94h28.345v28.346h28.349v28.343z"
    />
    <g fill="#F8AFA5">
      <path
        d="M680.296 708.595H623.61v28.345H566.924v28.346H623.61v28.343H566.924v28.341h-56.69v28.348H623.61v-28.346h28.341v28.346h28.345V821.97h-28.345v-28.341h28.345V736.94h28.345v-28.345zM510.234 736.94h28.347v28.346h-28.347z"
      />
    </g>
    <g fill="#F8AFA5">
      <path
        d="M170.109 765.286V736.94h28.344v-28.345h-85.037v28.345h-28.34v28.346h28.34v28.343H56.731v-28.343H.043v28.343h28.344v28.343h56.689v28.346h56.685v-28.346h28.348v28.346h28.344V821.97h-28.344v-28.341z"
      />
      <path d="M56.731 708.595H28.387v28.345h56.689v-28.345z" />
    </g>
  </svg>
</template>
