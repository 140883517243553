import { ref } from 'vue';

export function notification() {
  const notification = ref<string | boolean>(false);
  function notificationDismiss() {
    notification.value = false;
  }
  function notificationAddListeners() {
    window.addEventListener('click', notificationDismiss);
    window.addEventListener('keyup', notificationDismiss);
  }
  function notificationRemoveListeners() {
    window.removeEventListener('click', notificationDismiss);
    window.removeEventListener('keyup', notificationDismiss);
  }

  return {
    notification,
    notificationDismiss,
    notificationAddListeners,
    notificationRemoveListeners,
  };
}
