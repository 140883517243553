
import {
  ref,
  computed,
  onMounted,
  defineAsyncComponent,
  nextTick,
  reactive,
  onBeforeUnmount,
} from 'vue';
import { debounce } from 'lodash-es';
import { useStore } from '@/store';
import { apolloClient } from '@/graphql/client';
import CollectionQuery from '@/graphql/queries/Collection.gql';
import DesktopMenu from '@/components/DesktopMenu.vue';
import Bios from '@/components/Bios.vue';
import TaskBar from '@/components/TaskBar.vue';
import DesktopIcons from '@/components/DesktopIcons.vue';
import { getNodes, setDefaultVariantTitle } from '@/util/transformers';
import { cartCount as cartCountHelper } from '@/util/helpers';
import { ResponseCollection } from '@/types/responses';

export default {
  components: {
    Bios,
    DesktopMenu,
    DesktopIcons,
    TaskBar,
    BounceImage: defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "bounce" */ './components/playground/BounceImage.vue'
        )
    ),
    ThreeSpace: defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "three" */ './components/playground/ThreeSpace.vue'
        )
    ),
  },
  setup() {
    const viewport = ref(null);
    const store = useStore();
    const windowDimensions = reactive({
      h: 0,
      w: 0,
    });
    // Backgrounds
    const effectShake = computed(() => store.state.effects.shake);
    const threeSpace = computed(() => store.state.backgrounds.space);
    const effectMirror = computed(() => store.state.effects.mirror);
    const backgroundBounce = computed(() => store.state.backgrounds.bounce);
    // Cart
    const cartCount = computed(() => {
      return cartCountHelper(store.state.cart);
    });
    // Desktop height
    const setVh = () => {
      const { innerHeight, innerWidth } = window;
      const vh = innerHeight * 0.01;
      windowDimensions.h = innerHeight;
      windowDimensions.w = innerWidth;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // Products
    const onResize = debounce(setVh, 200);

    onMounted(async () => {
      setVh();
      window.addEventListener('resize', onResize);
      const result = await apolloClient.query<ResponseCollection>({
        query: CollectionQuery,
        variables: {
          handle: 'all',
        },
      });
      const edges = result.data.collectionByHandle?.products || { edges: [] };
      const products = getNodes(edges).map((item) => ({
        id: item.id,
        title: item.title,
        handle: item.handle,
        type: item.productType,
        variants: getNodes(item.variants).map(setDefaultVariantTitle),
        image: item.images.edges[0]?.node,
      }));
      store.commit.productsSet(products);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResize);
    });

    const biosShow = ref(true);
    const minTime = ref(false);
    const ready = computed(() => !!store.state.products && minTime.value);
    const biosOnLoad = async () => {
      await nextTick();
      biosShow.value = false;
    };
    const initialize = () => store.commit.initialize();

    setTimeout(() => (minTime.value = true), 2000);

    // Menu
    const menuActive = ref(false);
    const menuProps = ref({});
    const menuShow = (e: Event) => {
      menuActive.value = true;
      menuProps.value = e;
    };
    const menuClose = () => (menuActive.value = false);

    // Shutdown
    const shutdown = () => {
      store.commit.initialize(false);
      const shutdownEvent = new Event('shutdown');
      window.dispatchEvent(shutdownEvent);
    };

    return {
      backgroundBounce,
      biosShow,
      cartCount,
      effectMirror,
      effectShake,
      initialize,
      ready,
      menuActive,
      menuClose,
      menuProps,
      menuShow,
      shutdown,
      biosOnLoad,
      threeSpace,
      viewport,
      windowDimensions,
    };
  },
};
