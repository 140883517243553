<template>
  <BaseWindow
    title="Credits"
    :scroll-y="false"
    theme="solid"
    header-background=""
    content-class=""
    class="credits"
    v-bind="sizes"
    :allow-resize="false"
    :allow-maximize="false"
    @dismiss="onDismiss"
  >
    <div class="text-content text-sm">
      <h3 class="uppercase">Webpage</h3>
      <p>
        Built by
        <a href="https://rupertdunk.com" target="_blank">R. Dunk</a>.
        <br />
        Using
        <a href="https://v3.vuejs.org/" target="_blank">Vue</a>,
        <a href="https://threejs.org/" target="_blank">Three</a> and
        <a href="https://tailwindcss.com/" target="_blank">Tailwind</a>.
      </p>
      <h3 class="uppercase">Font</h3>
      <p>
        <a
          href="https://int10h.org/oldschool-pc-fonts/fontlist/#subs-ibm-g2-vga"
          target="_blank"
          >IBM VGA by VileR</a
        >. <br />Modified by
        <a href="https://rupertdunk.com" target="_blank">R. Dunk</a>.
      </p>
      <h3 class="uppercase">Terminal</h3>
      <p>
        <a href="https://xtermjs.org/" target="_blank">Xterm.js</a> with
        <a href="https://github.com/wavesoft/local-echo" target="_blank"
          >local-echo</a
        >.
      </p>
      <h3 class="uppercase">RIP</h3>
      <p>
        <a href="https://www.youtube.com/watch?v=7Pm1266IFqQ" target="_blank"
          >The GamesMaster</a
        >.
        <br />
        <a href="https://en.wikipedia.org/wiki/Patrick_Moore" target="_blank"
          >Sir Patrick Moore</a
        >.
      </p>
    </div>
  </BaseWindow>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { props, components, emits } from '@/mixins/view';
import { useViewport } from '@/router/viewport';

export default defineComponent({
  components,
  props,
  emits,
  setup(props, { emit }) {
    const viewportDimensions = useViewport();
    const width = 300;
    const height = 300;
    const x = viewportDimensions.w / 2 - width / 2;
    const y = viewportDimensions.h / 2 - height / 2;
    const onDismiss = () => emit('dismiss');
    return { sizes: { x, y, width, height }, onDismiss };
  },
});
</script>

<style lang="postcss" scoped>
.credits ::v-deep(.content) {
  animation: credits 600s linear infinite;
}

.credits ::v-deep(.content:hover) {
  animation-play-state: paused;
}

@keyframes credits {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
