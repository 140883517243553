<template>
  <div class="table table-fixed min-w-full leading-none">
    <div class="table-header-group">
      <div class="table-row">
        <div class="table-cell lg:w-10/12">Product</div>
        <div class="table-cell lg-only lg:w-2/12">Type</div>
        <div class="table-cell lg:w-2/12">Variants</div>
      </div>
    </div>
    <div class="table-row-group">
      <BasedLink
        v-for="product of products"
        :key="product.id"
        :to="product.link"
        class="table-row"
      >
        <div class="table-cell">
          <div class="flex items-center h-12">
            <img class="h-full" :src="product.image.thumbnailSmall" />
            <span class="ml-2 uppercase">{{ product.title }}</span>
          </div>
        </div>
        <div class="table-cell lg-only text-sm text-gray-600">
          {{ product.type }}
        </div>
        <div class="table-cell">
          <span
            v-for="variant of product.variants"
            :key="variant.id"
            class="ml-1 text-sm"
            :class="
              variant.availableForSale ? 'text-gray-600' : 'text-gray-300'
            "
          >
            {{ variant.title }}
          </span>
        </div>
      </BasedLink>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    products: {
      type: Object,
      required: true,
    },
  },
});
</script>

<style lang="postcss" scoped>
.table-header-group {
  @apply bg-gray-300;
}

.table-cell {
  @apply py-1 px-2;
}

.table-header-group .table-cell {
  @apply border-b border-r border-black;
}

.table-header-group .table-cell:last-child {
  @apply border-r-0;
}

.table-row-group .table-cell {
  @apply align-middle border-b border-dotted border-gray-500;
}

.table-row-group .table-row:last-child .table-cell {
  @apply border-none;
}

.table-row-group .table-row:hover,
.table-row-group .table-row:focus {
  @apply bg-gray-200 outline-none;
}

.lg-only {
  @apply hidden;
}
@screen lg {
  .lg-only {
    display: table-cell;
  }
}
</style>
