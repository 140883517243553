<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240.95 240.95">
    <g fill="#fff">
      <path
        d="M120.47 67.77h15.06v-7.53h-30.12v7.53h15.06zM52.71 112.95h7.53V75.3H45.18v7.53H30.12v7.53h-7.53v7.53h-7.53v7.53H7.53v7.53h7.53v7.53h7.53v7.53h7.53v7.53h7.53v7.53h7.53v-15.06h7.53v-15.06z"
      />
      <path
        d="M180.71 120.48v-7.53h-7.53V67.77h-7.53v-7.53h-15.06v7.53h-15.06v7.53h-30.12v-7.53H90.36v-7.53H75.3v7.53h-7.53v45.18h-7.53v15.06h-7.53v105.41h135.53V128.01h-7.53v-7.53z"
      />
      <path
        d="M225.89 105.42v-7.53h-7.53v-7.53h-7.53v-7.53h-15.06V75.3h-15.06v37.65h7.53v15.06h7.53v15.06h7.53v-7.53h7.53v-7.53h7.53v-7.53h7.53v-7.53h7.53v-7.53h-7.53z"
      />
    </g>
    <path
      fill="#231f20"
      d="M188.24 143.07V233.42H52.71V128.01h-7.53v15.06h-7.53v7.53h7.53V240.95h150.59V128.01h-7.53v15.06zM60.24 120.48v-7.53h-7.53v15.06h7.53v-7.53zM67.77 105.42V67.77h7.53v-7.53H60.24v7.53H45.18v7.53h15.06v37.65h7.53v-7.53zM180.71 128.01h7.53v-15.06h-7.53v15.06zM97.89 67.77h7.52v-7.53h30.12v7.53h15.06v-7.53h-7.53v-7.53H97.89v7.53h-7.53v7.53h7.53zM90.36 60.24v-7.53H75.3v7.53h15.06zM120.47 67.77h-15.06v7.53h30.12v-7.53h-15.06zM22.59 82.83h7.53v7.53h-7.53zM210.83 82.83h7.53v7.53h-7.53zM203.3 75.3h-7.53v7.53h15.06V75.3h-7.53zM7.53 112.95h7.53v7.53H7.53zM225.89 112.95h7.53v7.53h-7.53zM22.59 128.01h7.53v7.53h-7.53zM37.65 75.3h-7.53v7.53h15.06V75.3h-7.53zM30.12 135.54h7.53v7.53h-7.53zM15.06 105.42v-7.53H7.53v7.53H0v7.53h7.53v-7.53h7.53zM233.42 105.42h7.53v7.53h-7.53zM188.24 67.77h-7.53v-7.53h-15.06v7.53h7.53v45.18h7.53V75.3h15.06v-7.53h-7.53zM15.06 90.36h7.53v7.53h-7.53zM218.36 90.36h7.53v7.53h-7.53zM225.89 97.89h7.53v7.53h-7.53zM15.06 120.48h7.53v7.53h-7.53zM218.36 120.48h7.53v7.53h-7.53zM210.83 128.01h7.53v7.53h-7.53zM203.3 135.54h7.53v7.53h-7.53zM195.77 143.07h7.53v7.53h-7.53zM165.65 60.24v-7.53h-15.06v7.53h15.06z"
    />
    <path
      fill="#91d1bc"
      d="M120.47 0h-7.53v7.53H128V0h-7.53zM105.41 15.06v7.53h7.53V7.53h-7.53v7.53zM128 45.18v7.53h15.06v-7.53H128zM128 30.12h7.53V7.53H128v15.06h-7.53v15.06h-7.53v7.53H128V30.12zM90.36 52.71h7.53v7.53h-7.53zM143.06 52.71h7.53v7.53h-7.53zM97.89 45.18v7.53h15.05v-7.53H97.89z"
    />
    <path
      fill="#231f20"
      d="M135.53 135.54h7.53v7.53h-7.53zM97.89 135.54h7.53v7.53h-7.53zM135.53 150.6h7.53v7.53h-7.53zM97.89 150.6h7.53v7.53h-7.53zM120.47 158.13h-15.06v7.53h30.12v-7.53h-15.06z"
    />
    <path
      fill="#f8afa5"
      d="M150.59 120.48V113h-7.53v-7.53H97.89V113h-7.53v7.53h-7.54v45.18h7.54v7.52h7.53v7.53h45.17v-7.53h7.53v-7.52h7.53v-45.23zm-7.53 22.59h-7.53v-7.53h7.53zm0 15.05h-7.53v7.54h-30.12v-7.54h-7.52v-7.52h7.52v7.52h30.12v-7.52h7.53zm-37.65-15.05h-7.52v-7.53h7.52z"
    />
  </svg>
</template>
