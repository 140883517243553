<template>
  <transition name="fade" @after-leave="initialize">
    <Bios
      v-if="biosShow"
      class="fixed w-screen h-screen z-50"
      :loaded="ready"
      @load="biosOnLoad"
    />
  </transition>
  <DesktopMenu
    v-if="menuActive"
    class="z-20"
    v-bind="menuProps"
    @dismiss="menuClose"
    @shutdown="shutdown"
  />
  <div class="h-full pb-8" :class="{ mirror: effectMirror }">
    <BasedViewport
      ref="viewport"
      class="h-full relative z-10"
      transition-name="zoom"
      :transition-duration="250"
      :class="{ shake: effectShake }"
      :window-dimensions="windowDimensions"
    >
      <ThreeSpace
        v-if="threeSpace"
        class="absolute top-0 left-0 w-full h-full z-0"
      />
      <BounceImage
        v-if="backgroundBounce"
        class="absolute top-0 left-0 w-full h-full z-0"
      />
      <DesktopIcons :cart-count="cartCount" @rightclick="menuShow" />
    </BasedViewport>
  </div>
  <TaskBar
    class="fixed bottom-0 z-40"
    :class="{ mirror: effectMirror }"
    :cart-count="cartCount"
    @shutdown="shutdown"
  />
</template>

<script lang="ts">
import {
  ref,
  computed,
  onMounted,
  defineAsyncComponent,
  nextTick,
  reactive,
  onBeforeUnmount,
} from 'vue';
import { debounce } from 'lodash-es';
import { useStore } from '@/store';
import { apolloClient } from '@/graphql/client';
import CollectionQuery from '@/graphql/queries/Collection.gql';
import DesktopMenu from '@/components/DesktopMenu.vue';
import Bios from '@/components/Bios.vue';
import TaskBar from '@/components/TaskBar.vue';
import DesktopIcons from '@/components/DesktopIcons.vue';
import { getNodes, setDefaultVariantTitle } from '@/util/transformers';
import { cartCount as cartCountHelper } from '@/util/helpers';
import { ResponseCollection } from '@/types/responses';

export default {
  components: {
    Bios,
    DesktopMenu,
    DesktopIcons,
    TaskBar,
    BounceImage: defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "bounce" */ './components/playground/BounceImage.vue'
        )
    ),
    ThreeSpace: defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "three" */ './components/playground/ThreeSpace.vue'
        )
    ),
  },
  setup() {
    const viewport = ref(null);
    const store = useStore();
    const windowDimensions = reactive({
      h: 0,
      w: 0,
    });
    // Backgrounds
    const effectShake = computed(() => store.state.effects.shake);
    const threeSpace = computed(() => store.state.backgrounds.space);
    const effectMirror = computed(() => store.state.effects.mirror);
    const backgroundBounce = computed(() => store.state.backgrounds.bounce);
    // Cart
    const cartCount = computed(() => {
      return cartCountHelper(store.state.cart);
    });
    // Desktop height
    const setVh = () => {
      const { innerHeight, innerWidth } = window;
      const vh = innerHeight * 0.01;
      windowDimensions.h = innerHeight;
      windowDimensions.w = innerWidth;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // Products
    const onResize = debounce(setVh, 200);

    onMounted(async () => {
      setVh();
      window.addEventListener('resize', onResize);
      const result = await apolloClient.query<ResponseCollection>({
        query: CollectionQuery,
        variables: {
          handle: 'all',
        },
      });
      const edges = result.data.collectionByHandle?.products || { edges: [] };
      const products = getNodes(edges).map((item) => ({
        id: item.id,
        title: item.title,
        handle: item.handle,
        type: item.productType,
        variants: getNodes(item.variants).map(setDefaultVariantTitle),
        image: item.images.edges[0]?.node,
      }));
      store.commit.productsSet(products);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResize);
    });

    const biosShow = ref(true);
    const minTime = ref(false);
    const ready = computed(() => !!store.state.products && minTime.value);
    const biosOnLoad = async () => {
      await nextTick();
      biosShow.value = false;
    };
    const initialize = () => store.commit.initialize();

    setTimeout(() => (minTime.value = true), 2000);

    // Menu
    const menuActive = ref(false);
    const menuProps = ref({});
    const menuShow = (e: Event) => {
      menuActive.value = true;
      menuProps.value = e;
    };
    const menuClose = () => (menuActive.value = false);

    // Shutdown
    const shutdown = () => {
      store.commit.initialize(false);
      const shutdownEvent = new Event('shutdown');
      window.dispatchEvent(shutdownEvent);
    };

    return {
      backgroundBounce,
      biosShow,
      cartCount,
      effectMirror,
      effectShake,
      initialize,
      ready,
      menuActive,
      menuClose,
      menuProps,
      menuShow,
      shutdown,
      biosOnLoad,
      threeSpace,
      viewport,
      windowDimensions,
    };
  },
};
</script>

<style lang="postcss" scoped>
.mirror {
  transform: scaleX(-1);
}

.shake {
  animation: shake 5s linear infinite;
}

@keyframes shake {
  2% {
    transform: translate(1px, 5px) rotate(-1.5deg);
  }
  4% {
    transform: translate(7px, 4px) rotate(-2.5deg);
  }
  6% {
    transform: translate(8px, -2px) rotate(-0.5deg);
  }
  8% {
    transform: translate(2px, 10px) rotate(0.5deg);
  }
  10% {
    transform: translate(3px, 0px) rotate(-1.5deg);
  }
  12% {
    transform: translate(-8px, 9px) rotate(-1.5deg);
  }
  14% {
    transform: translate(-9px, -6px) rotate(-0.5deg);
  }
  16% {
    transform: translate(8px, -2px) rotate(0.5deg);
  }
  18% {
    transform: translate(-1px, -5px) rotate(1.5deg);
  }
  20% {
    transform: translate(-2px, -5px) rotate(-2.5deg);
  }
  22% {
    transform: translate(-4px, -3px) rotate(-2.5deg);
  }
  24% {
    transform: translate(-7px, 5px) rotate(-0.5deg);
  }
  26% {
    transform: translate(-5px, 5px) rotate(1.5deg);
  }
  28% {
    transform: translate(-1px, 6px) rotate(3.5deg);
  }
  30% {
    transform: translate(-2px, 6px) rotate(-0.5deg);
  }
  32% {
    transform: translate(2px, 10px) rotate(-0.5deg);
  }
  34% {
    transform: translate(-9px, 1px) rotate(0.5deg);
  }
  36% {
    transform: translate(-7px, 5px) rotate(3.5deg);
  }
  38% {
    transform: translate(-7px, 9px) rotate(1.5deg);
  }
  40% {
    transform: translate(7px, -8px) rotate(-0.5deg);
  }
  42% {
    transform: translate(8px, 7px) rotate(-0.5deg);
  }
  44% {
    transform: translate(-3px, -2px) rotate(0.5deg);
  }
  46% {
    transform: translate(1px, 2px) rotate(-2.5deg);
  }
  48% {
    transform: translate(-6px, -2px) rotate(-2.5deg);
  }
  50% {
    transform: translate(-8px, -1px) rotate(-1.5deg);
  }
  52% {
    transform: translate(5px, 10px) rotate(-1.5deg);
  }
  54% {
    transform: translate(7px, -9px) rotate(3.5deg);
  }
  56% {
    transform: translate(-7px, -5px) rotate(0.5deg);
  }
  58% {
    transform: translate(3px, 3px) rotate(0.5deg);
  }
  60% {
    transform: translate(4px, -5px) rotate(3.5deg);
  }
  62% {
    transform: translate(9px, 9px) rotate(1.5deg);
  }
  64% {
    transform: translate(-5px, 10px) rotate(-0.5deg);
  }
  66% {
    transform: translate(-8px, -9px) rotate(-2.5deg);
  }
  68% {
    transform: translate(-4px, -8px) rotate(-2.5deg);
  }
  70% {
    transform: translate(6px, -8px) rotate(1.5deg);
  }
  72% {
    transform: translate(8px, -6px) rotate(-1.5deg);
  }
  74% {
    transform: translate(-6px, 7px) rotate(1.5deg);
  }
  76% {
    transform: translate(2px, 5px) rotate(-1.5deg);
  }
  78% {
    transform: translate(2px, 0px) rotate(-2.5deg);
  }
  80% {
    transform: translate(-7px, 3px) rotate(-2.5deg);
  }
  82% {
    transform: translate(-9px, -4px) rotate(0.5deg);
  }
  84% {
    transform: translate(4px, 9px) rotate(3.5deg);
  }
  86% {
    transform: translate(-7px, 6px) rotate(-0.5deg);
  }
  88% {
    transform: translate(3px, -3px) rotate(-1.5deg);
  }
  90% {
    transform: translate(1px, -3px) rotate(-0.5deg);
  }
  92% {
    transform: translate(4px, 3px) rotate(2.5deg);
  }
  94% {
    transform: translate(9px, 1px) rotate(3.5deg);
  }
  96% {
    transform: translate(-8px, 3px) rotate(0.5deg);
  }
  98% {
    transform: translate(-8px, -7px) rotate(-2.5deg);
  }
  0%,
  100% {
    transform: translate(0, 0) rotate(0);
  }
}
</style>
