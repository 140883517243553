<template>
  <div
    class="bg-black text-based flex flex-col justify-start items-center p-6 sm:p-10"
  >
    <div
      class="text-right ml-auto text-xs sm:text-sm lg:text-base leading-none"
    >
      <img class="w-16 lg:w-24 mx-auto" src="burning.gif" alt="" />
      <div :class="$style.energyTitle">HIGH LEVEL</div>
      <div :class="$style.energySubtitle">FREE MEMORY PREVENTER</div>
    </div>
    <div class="uppercase mt-auto lg:mt-0 sm:text-lg md:text-xl">
      Based – High Level Offline Gear
      <br />
      Copyright (C) {{ year }} Based.Cool Ltd
      <br />
      <br />
      Build {{ buildNumber }} : {{ datetime }}
      <br />
      <br />
      Main Processor : Big Brain
      <br />
      Some Numbers : {{ memory }}K
      <br />
      <br />
      Booting {{ boot }}
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from 'vue';
import { format } from 'date-fns';

function randomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export default defineComponent({
  props: {
    loaded: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['load'],
  setup(props, { emit }) {
    const data = reactive({
      version: '',
      platform: '',
    });
    const bootIndex = ref(0);
    const bootArr = ['/', '—', '\\', '|'];
    setInterval(() => {
      if (bootIndex.value >= bootArr.length - 1) {
        bootIndex.value = 0;
      } else {
        bootIndex.value += 1;
      }
    }, 100);
    const memory = ref(999999);
    const buildNumber = ref(9999);
    setInterval(() => {
      buildNumber.value = randomInt(1000, 9999);
      memory.value = randomInt(100000, 999999);
    }, 100);

    const now = new Date();
    const year = ref('');
    year.value = format(now, 'yyyy');
    const datetime = ref('');
    datetime.value = format(now, 'yyMMddHHmm zzzz');

    const boot = computed(() =>
      props.loaded ? ' ... SUCCESS!' : bootArr[bootIndex.value]
    );
    watch(
      () => props.loaded,
      () => {
        emit('load');
      }
    );
    onMounted(() => {
      data.version = navigator.appVersion;
      data.platform = navigator.platform;
    });
    return { boot, data, year, datetime, memory, buildNumber };
  },
});
</script>

<style lang="postcss" module>
.energy {
}

.energyTitle {
  color: #feff00;
  font-size: 2.1em;
  @apply mt-2;
}

.energySubtitle {
  color: #0cdf00;
  border-color: #0cdf00;
  @apply border-t pt-1 mt-1;
}
</style>
