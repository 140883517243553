<template>
  <div
    class="absolute w-full h-full inset-0 flex justify-center items-center p-4"
  >
    <div
      :class="background"
      class="content flex items-center border-4 rounded border-double border-white text-white shadow p-4 max-w-lg"
      style="z-index: 9999999"
    >
      <img class="flex-shrink-0 w-16 sm:w-20 mr-4" src="/face-xs.png" />
      <div class="leading-tight text-lg">
        <slot>{{ text }}</slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount } from 'vue';

export default defineComponent({
  props: {
    text: {
      type: String,
      default: '',
    },
    background: {
      type: String,
      default: 'bg-ms',
    },
  },
  emits: ['dismiss'],
  setup(props, { emit }) {
    onBeforeUnmount(() => {
      emit('dismiss');
    });
  },
});
</script>
