<template>
  <BaseWindow
    title="Cart"
    container-class=""
    v-bind="sizes"
    @dismiss="onDismiss"
  >
    <CartTable
      v-if="items.length"
      :items="items"
      @remove="removeItem"
      @update:quantity="updateItemQuantity"
    />
    <BaseException v-else component="BasedLink" :to="{ name: 'shop' }">
      <p class="max-w-lg text-left mt-4">
        EmptyCartException: The cart is completely empty. The current customer
        should be terminated.
      </p>
      <p class="max-w-lg mt-4">Click here to buy gear</p>
    </BaseException>
    <template v-if="items.length" #status>
      <div class="bg-white p-2 w-full">
        <a
          v-if="checkoutUrl"
          :href="checkoutUrl"
          class="btn w-full"
          @click="saveCheckoutTimestamp"
          >Checkout (£{{ total.toFixed(2) }})</a
        >
        <span v-else class="btn w-full">Calculating... </span>
      </div>
    </template>
  </BaseWindow>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useStore } from '@/store';
import { saveCheckoutTimestamp } from '@/util/local-storage';
import BaseException from '@/components/BaseException.vue';
import CartTable from '@/components/CartTable.vue';
import {
  props,
  components as viewComponents,
  emits,
  sizes,
} from '@/mixins/view';
import { CartItem } from '@/types/product';

const sortByKey = (key: 'variantTitle' | 'productTitle') => (
  a: CartItem,
  b: CartItem
) => {
  const nameA = a[key].toLowerCase();
  const nameB = b[key].toLowerCase();
  if (nameA < nameB)
    //sort string ascending
    return -1;
  if (nameA > nameB) return 1;
  return 0; //default return value (no sorting)
};

export default defineComponent({
  components: {
    ...viewComponents,
    BaseException,
    CartTable,
  },
  props,
  emits,
  setup(props, { emit }) {
    const store = useStore();
    const onDismiss = () => emit('dismiss');
    const items = computed(() =>
      [...store.state.cart]
        .sort(sortByKey('variantTitle'))
        .sort(sortByKey('productTitle'))
    );
    const removeItem = store.commit.cartRemove;
    const total = computed(() =>
      items.value.reduce(
        (total, item) => (total += item.price * item.quantity),
        0
      )
    );
    const updateItemQuantity = (item: {
      variantId: string;
      quantity: number;
    }) => {
      if (item.quantity <= 0) {
        store.commit.cartRemove(item.variantId);
      } else {
        store.commit.cartItemQuantity(item.variantId, item.quantity);
      }
    };
    const checkoutUrl = computed(() => store.state.checkoutUrl);
    return {
      items,
      checkoutUrl,
      onDismiss,
      removeItem,
      updateItemQuantity,
      total,
      saveCheckoutTimestamp,
      sizes: sizes(),
    };
  },
});
</script>
