import { ref, onBeforeUnmount, onMounted } from 'vue';
import { getEventClientPos } from '@/util/event-pos';
import { useViewport } from '@/router/viewport';

export const emits = [
  'mounted',
  'focus',
  'dismiss',
  'resize-end',
  'resize-start',
  'move-end',
  'move-start',
];

export function mixin({
  startX,
  startY,
  startW,
  startH,
  emit,
}: {
  startX: number;
  startY: number;
  startW: number;
  startH: number;
  emit: (event: string, ...args: any[]) => void;
}) {
  const viewportDimensions = useViewport();
  const maximized = ref(false);
  const animating = ref(false);

  let raf: number | null = null;
  let deltaX = 0;
  let deltaY = 0;
  let deltaW = 0;
  let deltaH = 0;
  let maxX = 0;
  let maxY = 0;
  let maxW = 0;
  let maxH = 0;
  let lastX = startX;
  let lastY = startY;
  let clickedX = 0;
  let clickedY = 0;
  let width = startW;
  let height = startH;
  let lastWidth = width;
  let lastHeight = height;
  let x = startX;
  let y = startY;

  // Refs
  const el = ref<HTMLElement | null>(null);
  const barEl = ref<HTMLElement | null>(null);
  const resizerEl = ref<HTMLElement | null>(null);

  const focus = () => emit('focus');
  const dismiss = () => emit('dismiss');

  const checkDimensions = () => {
    if (x + width > viewportDimensions.w) {
      width = viewportDimensions.w * 0.8;
      x = viewportDimensions.w * 0.1;
    }
    if (y + height > viewportDimensions.h) {
      height = viewportDimensions.h * 0.8;
      y = viewportDimensions.h * 0.1;
    }
  };

  const size = () => {
    if (el.value) {
      el.value.style.width = `${width}px`;
      el.value.style.height = `${height}px`;
    }
  };

  const move = () => {
    if (el.value) {
      el.value.style.transform = `translate(${x}px, ${y}px)`;
    }
  };

  const maximize = () => {
    if (!animating.value && el.value) {
      animating.value = true;
      el.value.style.transition = '250ms ease all';
      if (maximized.value === true) {
        checkDimensions();
        move();
        size();
        maximized.value = false;
      } else {
        el.value.style.transform = 'translate(0px, 0px)';
        el.value.style.width = '100%';
        el.value.style.height = '100%';
        maximized.value = true;
      }
      setTimeout(() => {
        if (el.value) {
          el.value.style.transition = '';
        }
        animating.value = false;
      }, 250);
    }
  };

  function addListeners(move: EventHandlerNonNull, stop: EventHandlerNonNull) {
    window.addEventListener('mousemove', move, { passive: false });
    window.addEventListener('touchmove', move, { passive: false });
    window.addEventListener('mouseup', stop, { passive: true });
    window.addEventListener('touchend', stop, { passive: true });
    window.addEventListener('touchcancel', stop, { passive: true });
  }

  function removeListeners(
    move: EventHandlerNonNull,
    stop: EventHandlerNonNull
  ) {
    window.removeEventListener('mousemove', move);
    window.removeEventListener('touchmove', move);
    window.removeEventListener('mouseup', stop);
    window.removeEventListener('touchend', stop);
    window.removeEventListener('touchcancel', stop);
  }

  function updateMove() {
    x = Math.min(Math.max(deltaX, 0), maxX);
    y = Math.min(Math.max(deltaY, 0), maxY);
    move();
    raf = null;
  }

  function onMove(e: Event) {
    e.preventDefault();
    if (!raf) {
      const { x: eventX, y: eventY } = getEventClientPos(e);
      deltaX = lastX + eventX - clickedX;
      deltaY = lastY + eventY - clickedY;
      raf = requestAnimationFrame(updateMove);
    }
  }

  function stopMove() {
    lastX = x;
    lastY = y;
    removeListeners(onMove, stopMove);
    emit('move-end');
  }

  function startMove(e: Event) {
    e.preventDefault();
    if (maximized.value || !el.value) return;
    const { x: eventX, y: eventY } = getEventClientPos(e);
    clickedX = eventX;
    clickedY = eventY;
    maxX = viewportDimensions.w - el.value.offsetWidth;
    maxY = viewportDimensions.h - el.value.offsetHeight;
    addListeners(onMove, stopMove);
    emit('move-start');
  }

  function updateResize() {
    width = Math.min(Math.max(deltaW, 320), maxW);
    height = Math.min(Math.max(deltaH, 320), maxH);
    size();
    raf = null;
  }

  function onResize(e: Event) {
    if (!raf) {
      const { x: eventX, y: eventY } = getEventClientPos(e);
      deltaW = lastWidth + eventX - clickedX;
      deltaH = lastHeight + eventY - clickedY;
      raf = requestAnimationFrame(updateResize);
    }
  }

  function stopResize() {
    lastWidth = width;
    lastHeight = height;
    removeListeners(onResize, stopResize);
    emit('resize-end');
  }

  function startResize(e: Event) {
    if (maximized.value) return;
    const { x: eventX, y: eventY } = getEventClientPos(e);
    clickedX = eventX;
    clickedY = eventY;
    maxW = viewportDimensions.w - x;
    maxH = viewportDimensions.h - y;
    addListeners(onResize, stopResize);
    emit('resize-start');
  }

  const addMoveListeners = () => {
    if (barEl.value) {
      barEl.value.addEventListener('mousedown', startMove, { passive: false });
      barEl.value.addEventListener('touchstart', startMove, {
        passive: false,
      });
    }
  };
  const addResizeListeners = () => {
    if (resizerEl.value) {
      resizerEl.value.addEventListener('mousedown', startResize, {
        passive: false,
      });
      resizerEl.value.addEventListener('touchstart', startResize, {
        passive: false,
      });
    }
  };

  onMounted(() => {
    size();
    move();
    addMoveListeners();
    addResizeListeners();
    emit('mounted');
  });

  onBeforeUnmount(() => {
    if (barEl.value) {
      barEl.value.removeEventListener('mousedown', startMove);
      barEl.value.removeEventListener('touchstart', startMove);
    }
    if (resizerEl.value) {
      resizerEl.value.removeEventListener('mousedown', startResize);
      resizerEl.value.removeEventListener('touchstart', startResize);
    }
  });

  return {
    el,
    barEl,
    resizerEl,
    maximize,
    dismiss,
    focus,
  };
}
