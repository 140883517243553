<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 253.75 253.75">
    <path fill="#fff" d="M104.14 45.18v-7.53H89.09v22.59H111.67V45.18h-7.53z" />
    <path
      fill="#231f20"
      d="M134.26 45.18v-7.53h-7.53v-7.53h-22.59v15.06h7.53v15.06H89.09v7.53h7.52v7.53h7.53v7.53h22.59V75.3h7.53v-7.53h7.53V45.18h-7.53z"
    />
    <path
      fill="#77cabc"
      d="M149.32 37.65v-7.53h-7.53v-7.53h-15.06v-7.53h-22.59v7.53h-15v7.53h-7.58v15.06H74V75.3h7.53v7.53h7.53v7.53h7.53v7.53h37.64v-7.53h15.06V75.3h7.53V37.65zm-60.23 0h15v-7.53h22.59v7.53h7.53v7.53h7.53v22.59h-7.53v7.53h-7.53v7.53h-22.54V75.3h-7.52v-7.53h-7.53V37.65z"
    />
    <path
      fill="#fff"
      d="M194.5 52.71V30.12H187v-7.53h-7.53v-7.53h-7.53V7.53h-15.09V0H96.62v7.53H81.56v7.53H74v7.53h-7.5v7.53H59v22.59h-7.56v37.65H59V113h7.5v7.53H74V128h7.53v7.53h15.09v7.53h22.58v7.53h15.06v-7.53h22.59v-7.53h15.06V128h7.53v-7.53H187V113h7.53V90.36H202V52.71zm-120.5 0v-7.53h7.53V30.12h7.53v-7.53h15v-7.53h22.59v7.53h15.06v7.53h7.53v7.53h7.53V75.3h-7.53v15.06h-14.98v7.53H96.62v-7.53h-7.53v-7.53h-7.53V75.3H74V52.71z"
    />
    <path
      fill="#0b0c0c"
      d="M51.44 82.83V75.3H36.38v7.53h15.06zM209.56 75.3h-7.53v7.53h15.06V75.3h-7.53zM51.44 97.89h7.53v-7.53H36.38v7.53h15.06zM202.03 90.36h-7.53v7.53h22.59v-7.53h-15.06zM51.44 112.95h7.53v-7.53H36.38v7.53h15.06zM202.03 105.42h-7.53v7.53h22.59v-7.53h-15.06zM51.44 128.01h22.59v-7.53H36.38v7.53h15.06zM202.03 120.48h-22.59v7.53h37.65v-7.53h-15.06zM89.09 135.54H36.38v7.52H96.61v-7.52h-7.52zM202.03 135.54h-37.65v7.52h52.71v-7.52h-15.06zM149.32 143.06v7.54h15.06v-7.54h-15.06zM96.61 143.06v7.54h15.06v-7.54H96.61zM126.73 158.13v45.17h7.53v-52.7h-7.53v7.53zM81.56 150.6H36.38v7.53H96.61v-7.53H81.56zM171.91 158.13h45.18v-7.53h-52.71v7.53h7.53zM149.32 158.13v7.53h15.06v-7.53h-15.06zM104.14 165.66h7.53v-7.53H96.61v7.53h7.53zM81.56 165.66H36.38v7.53H96.61v-7.53H81.56zM171.91 173.19h45.18v-7.53h-52.71v7.53h7.53zM149.32 173.19v7.53h15.06v-7.53h-15.06zM104.14 180.72h7.53v-7.53H96.61v7.53h7.53z"
    />
    <path
      fill="#efdfcd"
      d="M224.62 60.24v-7.53h-22.59V75.3h15.06v7.53h-15.06v7.53h15.06v7.53H194.5v7.53h22.59v7.53h-30.12v7.53h30.12v7.53h-45.18v7.53h45.18v7.52h-52.71v7.54h52.71v7.53h-52.71v7.53h52.71v7.53h-52.71v7.53h-15.06v-7.53h15.06v-7.53h-15.06v-7.53h15.06v-7.53h-15.06v-7.54h-15.06V203.3h15.06v-7.53h30.12v-7.52h30.12v7.52h22.59V60.24h-7.53z"
    />
    <path
      fill="#efdfcd"
      d="M156.85 135.54h7.53v7.53h-7.53zM119.2 143.06h-7.53v7.54H96.61v7.53h15.06v7.53H96.61v7.53h15.06v7.53H96.61v-7.53H36.38v-7.53H96.61v-7.53H36.38v-7.53H96.61v-7.54H36.38v-7.52h45.18v-7.53H36.38v-7.53H66.5v-7.53H36.38v-7.53h22.59v-7.53H36.38v-7.53h15.06v-7.53H36.38V75.3h15.06V52.71H28.85v7.53h-7.53v135.53h22.59v-7.52h30.12v7.52h30.11v7.53h22.59v-52.7h-7.53v-7.54z"
    />
    <path
      fill="#03040"
      d="M239.68 67.77V52.71h-7.53v-7.53H194.5v7.53h30.12v7.53h7.53v135.53h-22.59v-7.52h-30.12v7.52h-30.12v7.53h-45.18v-7.53H74.03v-7.52H43.91v7.52H21.32V60.24h7.53v-7.53h30.12v-7.53H21.32v7.53h-7.53v15.06H6.26v150.59h105.41v7.53h30.12v-7.53h105.42V67.77h-7.53z"
    />
  </svg>
</template>