<template>
  <component
    :is="component"
    class="flex flex-col w-full h-full justify-center items-center bg-ms text-white text-lg leading-tight p-4"
  >
    <h3 class="bg-gray-400 text-ms px-1">{{ title }}</h3>
    <slot>
      <p class="max-w-lg text-left mt-4">
        UnknownException: the current application is badly coded.
      </p>
      <p class="max-w-lg mt-4">Maybe try again later</p>
    </slot>
  </component>
</template>

<script lang="ts">
export default {
  props: {
    title: {
      type: String,
      default: 'Based',
    },
    component: {
      type: String,
      default: 'div',
    },
  },
};
</script>
