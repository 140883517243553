<template>
  <BaseWindow
    title="Contact"
    v-bind="sizes"
    container-class=""
    :allow-resize="false"
    :allow-maximize="false"
    :scroll-y="false"
    @dismiss="onDismiss"
  >
    <div
      class="min-h-full flex flex-col items-center justify-center text-center leading-none"
    >
      <DesktopIcon
        text="Email BASED"
        to="mailto:basedcool3@gmail.com"
        size="max-w-48 p-6"
      >
        <IconEmail class="w-full" />
      </DesktopIcon>
      <DesktopIcon
        text="BASED on Instagram"
        to="https://www.instagram.com/based.gg/"
        size="max-w-48 p-6"
      >
        <IconInstagram class="w-full" />
      </DesktopIcon>
    </div>
  </BaseWindow>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
  sizesFixed,
  props,
  components as viewComponents,
  emits,
} from '@/mixins/view';
import DesktopIcon from '@/components/DesktopIcon.vue';
import IconEmail from '@/components/icons/IconEmail.vue';
import IconInstagram from '@/components/icons/IconInstagram.vue';

export default defineComponent({
  components: {
    ...viewComponents,
    DesktopIcon,
    IconEmail,
    IconInstagram,
  },
  props,
  emits,
  setup(props, { emit }) {
    const onDismiss = () => emit('dismiss');
    return { onDismiss, sizes: sizesFixed(280, 500) };
  },
});
</script>
