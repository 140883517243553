import { reactive, App, InjectionKey, watch, inject } from 'vue';
import { apolloClient } from '@/graphql/client';
import { debounce } from 'lodash-es';
import CheckoutCreate from '@/graphql/queries/CheckoutCreate.gql';
import CheckoutReplace from '@/graphql/queries/CheckoutReplace.gql';
import { saveCart, saveCheckout } from '@/util/local-storage';
import { Store, StoreState, StoreMutations } from '@/types/store';
import { CartItem, ProductId } from '@/types/product';
import { RouterWindow } from '@/types/router';
import { getLocalStorage } from '@/util/local-storage';
import { trackCartAdd } from '@/plugins/gtag';

export const storeKey: InjectionKey<Store> = Symbol('s');

const defaultState: StoreState = {
  backgrounds: {
    bounce: false,
    space: true,
  },
  cart: [],
  checkoutId: '',
  checkoutUrl: '',
  effects: {
    mirror: false,
    shake: false,
  },
  initialized: false,
  products: null,
  shopLayout: 'grid',
  space: {
    speed: 1,
  },
  windows: [],
};

export function createStore() {
  const state = reactive<StoreState>(defaultState);
  const commit: StoreMutations = {
    initialize(status = true) {
      state.initialized = status;
    },
    setSpaceSpeed(speed) {
      state.space.speed = speed;
    },
    toggleBackground(name) {
      if (name in state.backgrounds) {
        state.backgrounds[name] = !state.backgrounds[name];
      } else {
        throw Error('Background not found.');
      }
    },
    toggleEffect(name) {
      if (name in state.effects) {
        state.effects[name] = !state.effects[name];
      } else {
        throw Error('Effect not found.');
      }
    },
    resetEffects() {
      const { effects } = state;
      const keys = Object.keys(effects) as Array<keyof StoreState['effects']>;
      keys.forEach((e) => (effects[e] = false));
    },
    resetBackgrounds() {
      state.backgrounds.bounce = false;
      state.backgrounds.space = true;
    },
    cartAdd(product, variant) {
      const index = state.cart.findIndex((it) => it.variantId === variant.id);
      if (index > -1) {
        const item = state.cart[index];
        if (item.quantity < 10) {
          item.quantity++;
          trackCartAdd(item);
          return true;
        }
        return false;
      }
      const item: CartItem = {
        key: `${product.id}-${variant.id}`,
        productId: product.id,
        productTitle: product.title,
        productHandle: product.handle,
        variantId: variant.id,
        variantTitle: variant.title,
        price: parseInt(variant.priceV2.amount, 10),
        quantity: 1,
      };
      state.cart.push(item);
      trackCartAdd(item);
      return true;
    },
    cartRemove(variantId: ProductId) {
      const index = state.cart.findIndex((it) => it.variantId === variantId);
      if (index > -1) {
        state.cart.splice(index, 1);
      }
    },
    cartItemQuantity(variantId, quantity) {
      const item = state.cart.find((item) => item.variantId === variantId);
      if (item) {
        item.quantity = quantity;
      }
    },
    cartSet(cart) {
      state.cart = cart;
    },
    cartClear() {
      state.cart = [];
    },
    checkoutSet(checkout) {
      state.checkoutId = checkout.id;
      state.checkoutUrl = checkout.url;
      saveCheckout(checkout);
    },
    productsSet(products) {
      state.products = products;
    },
    activateWindow(window: RouterWindow) {
      const index = state.windows.findIndex((w) => w.id === window.id);
      if (index < 0) {
        state.windows.push(window);
      }
    },
    deactivateWindow(id: string) {
      const index = state.windows.findIndex((w) => w.id === id);
      if (index > -1) {
        state.windows.splice(index, 1);
      }
    },
    shopLayoutSet(layout) {
      state.shopLayout = layout;
    },
  };

  // Init from localStorage
  const { cart, checkout } = getLocalStorage();
  commit.checkoutSet(checkout);
  commit.cartSet(cart);

  // Checkout handling
  const fetchNewCheckout = async (cart: CartItem[]) => {
    if (state.checkoutId) {
      const result = await apolloClient.mutate({
        mutation: CheckoutReplace,
        variables: {
          checkoutId: state.checkoutId,
          lineItems: cart.map((i) => ({
            variantId: i.variantId,
            quantity: i.quantity,
          })),
        },
      });
      const { checkout } = result.data.checkoutLineItemsReplace;
      commit.checkoutSet({ id: checkout.id, url: checkout.webUrl });
    } else {
      const result = await apolloClient.mutate({
        mutation: CheckoutCreate,
        variables: {
          input: {
            lineItems: cart.map((i) => ({
              variantId: i.variantId,
              quantity: i.quantity,
            })),
          },
        },
      });
      const { checkout } = result.data.checkoutCreate;
      commit.checkoutSet({ id: checkout.id, url: checkout.webUrl });
    }
  };
  const fetchCheckoutDebounced = debounce(fetchNewCheckout, 2000);
  const onCartChange = async (cart: CartItem[]) => {
    saveCart(cart);
    commit.checkoutSet({ id: state.checkoutId, url: '' });
    fetchCheckoutDebounced(cart);
  };
  watch(() => state.cart, onCartChange, { deep: true });

  const store = {
    state,
    commit,
    install(app: App) {
      const store = { state, commit };
      app.provide(storeKey, store);
    },
  };
  return store;
}

export function useStore(): Store {
  return inject(storeKey)!;
}
