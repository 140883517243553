<template>
  <div class="w-full">
    <TaskBarMenu
      v-if="menuVisible"
      @dismiss="menuHide"
      @shutdown="onShutdown"
    />
    <div :class="$style.barContent">
      <button
        :class="[$style.sectionLeft, $style.sectionButton]"
        class="uppercase"
        @click="menuShow"
      >
        Menu
      </button>
      <BaseMarquee :content="marqueeContent" />
      <BasedLink
        class="flex"
        :class="[$style.section, $style.sectionButton]"
        :to="{ name: 'cart' }"
      >
        <IconPxCart class="h-4 mr-1" /> {{ cartCount }}
      </BasedLink>
      <BasedLink
        class="flex"
        :class="[$style.section, $style.sectionButton]"
        :to="{ name: 'ticktock' }"
      >
        <IconPxClock class="h-4 mr-1" />
        <span class="hidden sm:flex">{{ time }}</span>
      </BasedLink>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, computed, defineComponent } from 'vue';
import { format } from 'date-fns';
import { useQuery, useResult } from '@vue/apollo-composable';
import PageQuery from '@/graphql/queries/Page.gql';
import { useRoute } from '@/router';
import BaseMarquee from '@/components/BaseMarquee.vue';
import IconPxCart from '@/components/icons/IconPxCart.vue';
import IconPxClock from '@/components/icons/IconPxClock.vue';
import TaskBarMenu from '@/components/TaskBarMenu.vue';

export default defineComponent({
  components: {
    BaseMarquee,
    IconPxCart,
    IconPxClock,
    TaskBarMenu,
  },
  props: {
    cartCount: {
      type: Number,
      required: true,
    },
  },
  emits: ['shutdown'],
  setup(props, { emit }) {
    const route = useRoute();
    // Views
    const views = computed(() => route.views);
    // Time
    const time = ref('');
    const updateFormattedDateTime = () => {
      const now = new Date();
      time.value = format(now, 'HH:mm:ss');
    };
    updateFormattedDateTime();
    setInterval(updateFormattedDateTime, 1000);
    // Marquee
    const { result } = useQuery(PageQuery, {
      handle: 'marquee',
    });
    const marqueeContent = useResult(
      result,
      '',
      (data) => data.pageByHandle.bodySummary
    );
    // Menu
    const menuVisible = ref(false);
    const menuShow = () => {
      menuVisible.value = true;
    };
    const menuHide = () => {
      menuVisible.value = false;
    };
    const onShutdown = () => {
      emit('shutdown');
    };
    return {
      views,
      time,
      marqueeContent,
      menuVisible,
      menuShow,
      menuHide,
      onShutdown,
    };
  },
});
</script>

<style lang="postcss" module>
.barContent {
  @apply w-full h-8 flex bg-gray-300 text-black border-t
  border-gray-700;
  background-color: rgb(210, 210, 210);
}

.section {
  @apply h-full items-center px-2 border-l border-gray-500;
}

.sectionLeft {
  @apply h-full items-center px-2 border-r border-gray-500;
}

.section:last-child {
  @apply border-r-0;
}

.sectionButton:hover,
.sectionButton:focus {
  @apply text-based outline-none;
}
</style>
