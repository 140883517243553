<template>
  <BaseWindow
    title="Lookbook"
    v-bind="sizes"
    container-class=""
    ref="root"
    @dismiss="onDismiss"
  >
    <div
      v-for="(image, i) of images"
      :key="image.src"
      :ref="setItemRef"
      :class="$style.imageContainer"
    >
      <transition name="icon-fade">
        <picture v-if="image.awake" class="absolute inset-0 w-full h-full">
          <source :srcset="image.src.webp" type="image/webp" />
          <source :srcset="image.src.jpg" type="image/jpeg" />
          <img :src="image.src.jpg" :alt="`Lookbook image ${i + 1}`" />
        </picture>
      </transition>
    </div>
  </BaseWindow>
</template>

<script lang="ts">
import {
  defineComponent,
  onBeforeUnmount,
  onBeforeUpdate,
  onMounted,
  ref,
  ComponentPublicInstance,
} from 'vue';
import { sizes, props, components, emits } from '@/mixins/view';

export default defineComponent({
  components,
  props,
  emits,
  setup(props, { emit }) {
    const root = ref<ComponentPublicInstance | null>(null);
    const images = ref(
      [...Array(10).keys()].map((i) => ({
        src: {
          jpg: `lookbook/jpg/basedt01-lb${i + 1}.jpg`,
          webp: `lookbook/webp/basedt01-lb${i + 1}.webp`,
        },
        awake: false,
      }))
    );

    const onDismiss = () => emit('dismiss');

    let itemRefs: Array<HTMLElement> = [];
    const setItemRef = (el: HTMLElement) => {
      itemRefs.push(el);
    };
    let observer: IntersectionObserver | null = null;
    onMounted(() => {
      if (root.value) {
        observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                observer!.unobserve(entry.target);
                const index = itemRefs.findIndex((ref) => ref === entry.target);
                images.value[index].awake = true;
              }
            });
          },
          {
            root: root.value.$el,
          }
        );
        itemRefs.forEach((el) => {
          observer!.observe(el);
        });
      }
    });
    onBeforeUpdate(() => {
      itemRefs.forEach((item) => {
        observer!.unobserve(item);
      });
      itemRefs = [];
    });
    onBeforeUnmount(() => {
      observer!.disconnect();
    });

    return {
      root,
      images,
      onDismiss,
      setItemRef,
      sizes: sizes([0.4, 0.8]),
    };
  },
});
</script>

<style lang="postcss" module>
.imageContainer {
  @apply relative w-full;
  /* Current image ratio is 1.35 */
  padding-top: 135%;
  background-image: url('/lookbook/bg.png');
  background-size: 100%;
  background-repeat: repeat;
}
</style>
