
export default {
  props: {
    title: {
      type: String,
      default: 'Based',
    },
    component: {
      type: String,
      default: 'div',
    },
  },
};
