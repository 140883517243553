<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g fill="currentColor">
      <rect x="5" y="5" width="2" height="2" />
      <rect x="5" y="17" width="2" height="2" />
      <rect x="7" y="7" width="2" height="2" />
      <rect x="7" y="15" width="2" height="2" />
      <rect x="9" y="9" width="2" height="2" />
      <rect x="9" y="13" width="2" height="2" />
      <rect x="11" y="11" width="2" height="2" />
      <rect x="13" y="9" width="2" height="2" />
      <rect x="13" y="13" width="2" height="2" />
      <rect x="15" y="7" width="2" height="2" />
      <rect x="15" y="15" width="2" height="2" />
      <rect x="17" y="5" width="2" height="2" />
      <rect x="17" y="17" width="2" height="2" />
    </g>
  </svg>
</template>
