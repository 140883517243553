import ApolloClient from 'apollo-boost';

export const apolloClient = new ApolloClient({
  uri: process.env.VUE_APP_SHOPIFY_URI,
  request: operation => {
    const token = process.env.VUE_APP_SHOPIFY_TOKEN;
    operation.setContext({
      headers: {
        'X-Shopify-Storefront-Access-Token': token,
      },
    });
  },
});
