<template>
  <BasedLink
    ref="root"
    class="text-center flex flex-col items-center select-none focus:outline-none"
    :class="[$style.root, containerWidth]"
    :to="to"
    :prevent-navigation="preventNavigation"
    @click="onClick"
  >
    <div class="w-full m-auto" :class="$style.icon">
      <slot>
        <img class="w-full" :src="icon.src" :alt="icon.alt" />
      </slot>
    </div>
    <div :class="$style.text">
      <slot name="text"> {{ text }} </slot>
    </div>
  </BasedLink>
</template>

<script lang="ts">
import { ref, computed, onBeforeUnmount, PropType, defineComponent } from 'vue';
import { BasedLink } from '@/router/link';
import { RouteLocationRaw } from '@/types/router';
import { ShopProductImage } from '@/types/product';

export default defineComponent({
  props: {
    to: {
      type: [Object, String] as PropType<RouteLocationRaw | string>,
      required: true,
    },
    size: {
      type: String,
      default: '',
    },
    image: {
      type: Object as PropType<ShopProductImage>,
      default: null,
    },
    text: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const root = ref<InstanceType<typeof BasedLink> | null>(null);
    const highlighted = ref(false);
    // @TODO Better mobile detection
    const isMobile =
      typeof navigator !== 'undefined' && /Mobi/i.test(navigator.userAgent);

    const containerWidth = computed(() => {
      if (!props.size) return 'w-12 sm:w-16';
      if (props.size === 'lg') return 'w-32 sm:w-48';
      if (props.size === 'xl') return 'w-48 sm:w-56';
      return props.size;
    });

    function deselect() {
      highlighted.value = false;
    }

    function clickHandler(e: Event) {
      const el = root.value?.$el || root.value;
      if (el && el.contains && !el.contains(e.target)) {
        document.removeEventListener('click', clickHandler);
        deselect();
      }
    }

    function select() {
      highlighted.value = true;
      document.addEventListener('click', clickHandler);
    }

    function onClick() {
      if (highlighted.value || isMobile) {
        deselect();
      } else {
        select();
      }
    }

    const icon = computed(() => {
      return {
        src: props.image ? props.image.thumbnailLarge : '',
        alt: props.image?.altText,
      };
    });

    const preventNavigation = computed(() => {
      if (isMobile) return false;
      if (highlighted.value) return false;
      return true;
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', clickHandler);
    });

    return {
      icon,
      root,
      onClick,
      highlighted,
      containerWidth,
      preventNavigation,
    };
  },
});
</script>

<style lang="postcss" module>
.icon {
  @apply transition-transform duration-100;
}

.text {
  @apply mt-1 px-1 uppercase border border-dashed border-transparent leading-none relative;
}

.root:focus .icon {
  @apply transform scale-110;
}

.root:focus .text {
  @apply bg-gray-200 text-based border-based;
}
</style>
