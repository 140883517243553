import { App } from 'vue';
import { Router } from '@/router';
import VueGtag, { useGtag } from 'vue-gtag-next';
import { CartItem, ProductId } from '@/types/product';
import { RouteDefinition, RouteLocation } from '@/types/router';

export interface GtagItem {
  item_name: string;
  item_id: ProductId;
  item_variant?: string;
  price?: number | string;
  quantity?: number;
}

function trackInitialPageView(router: Router | undefined) {
  const { set, pageview } = useGtag();
  const views = router?.currentRoute.value.views;
  let page_title = 'home';
  if (views && views.length) {
    page_title = views[views.length - 1].name;
  }
  set({ page_title });
  pageview({ page_title });
}

function trackPushEvent(routes: RouteDefinition[]) {
  return function (view: RouteLocation) {
    const { set, pageview } = useGtag();
    const validRoute = routes.find((r) => r.name === view.name);
    if (validRoute) {
      const page_title = [view.name, view.param].filter((i) => i).join(' / ');
      set({ page_title });
      pageview({ page_title });
    }
  };
}

export function trackViewItem(item: GtagItem) {
  const { event } = useGtag();
  event('view_item', {
    currency: 'GBP',
    items: [item],
  });
}

export function trackCartAdd(item: CartItem) {
  const { event } = useGtag();
  const items: Array<GtagItem> = [
    {
      item_id: item.variantId,
      item_name: item.productTitle,
      item_variant: item.variantTitle,
      price: item.price,
      quantity: item.quantity,
    },
  ];
  event('add_to_cart', {
    currency: 'GBP',
    items,
  });
}

export const basedAnalytics = {
  install(app: App, options: { id: string; router?: Router; debug?: boolean }) {
    app.use(VueGtag, {
      property: {
        id: options.id,
      },
      useDebugger: options.debug || false,
    });

    // Log initial page view
    trackInitialPageView(options.router);

    // Log each page view after router push
    if (options.router) {
      const { routes } = options.router;
      options.router.afterPush(trackPushEvent(routes));
    }
  },
};
