<template>
  <BaseWindow
    title="Image"
    container-class=""
    v-bind="sizes"
    @dismiss="onDismiss"
  >
    <img
      class="w-full cursor-pointer max-w-full"
      :src="src"
      :alt="alt"
      @click="onDismiss"
    />
  </BaseWindow>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { components as viewComponents, sizes } from '@/mixins/view';
import { useRouter } from '@/router';

export default defineComponent({
  components: {
    ...viewComponents,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const router = useRouter();
    const onDismiss = () => {
      router.pullWindow(props.id);
    };
    return { onDismiss, sizes: sizes([0.3, 0.5], [0.8, 0.8]) };
  },
});
</script>
