
import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
import IconShop from '@/components/icons/IconShop.vue';
import IconLookbook from '@/components/icons/IconLookbook.vue';
import IconAbout from '@/components/icons/IconAbout.vue';
import IconContact from '@/components/icons/IconContact.vue';
import IconCart from '@/components/icons/IconCart.vue';
import IconShell from '@/components/icons/IconShell2.vue';

export default defineComponent({
  components: {
    IconShop,
    IconLookbook,
    IconAbout,
    IconContact,
    IconCart,
    IconShell,
  },
  emits: ['dismiss', 'shutdown'],
  setup(props, { emit }) {
    const links = ref([
      { to: 'shop', title: 'Shop', component: 'IconShop' },
      { to: 'lookbook', title: 'Lookbook', component: 'IconLookbook' },
      { to: 'about', title: 'About', component: 'IconAbout' },
      { to: 'contact', title: 'Contact', component: 'IconContact' },
      { to: 'cart', title: 'Cart', component: 'IconCart' },
      { to: 'baesh', title: 'Baesh', component: 'IconShell' },
    ]);
    const root = ref<HTMLElement | null>(null);

    const dismiss = () => {
      emit('dismiss');
    };

    const onKeyUp = (e: KeyboardEvent) => {
      const key = e.key || e.keyCode;
      if (key === 'Escape' || key === 'Esc' || key === 27) {
        dismiss();
      }
    };
    const onOutsideClick = (e: MouseEvent | TouchEvent) => {
      if (
        root.value &&
        e.target instanceof HTMLElement &&
        !root.value.contains(e.target)
      ) {
        dismiss();
      }
    };
    onMounted(async () => {
      // Timeout to prevent insta click
      setTimeout(() => {
        window.addEventListener('click', onOutsideClick);
        window.addEventListener('keyup', onKeyUp);
      }, 100);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('click', onOutsideClick);
      window.removeEventListener('keyup', onKeyUp);
    });
    const shutdown = () => {
      emit('dismiss');
      emit('shutdown');
    };
    return { dismiss, links, root, shutdown };
  },
});
