import { ref, watch } from 'vue';
import { useRouter } from '.';

export function wrapper(id: string, shouldWatch = true) {
  const router = useRouter();
  const el = ref<HTMLElement | null>(null);
  const incrementZ = () => {
    if (el.value) {
      router.top.value += 1;
      el.value.style.zIndex = router.top.value.toString();
    }
  };
  const setActive = () => {
    router.active.value = id;
  };
  const onWrapperMount = () => {
    setActive();
    incrementZ();
  };
  if (shouldWatch) {
    watch(
      () => router.active.value,
      (activeKey) => {
        if (activeKey === id) incrementZ();
      }
    );
  }
  return { el, setActive, onWrapperMount };
}
