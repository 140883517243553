
import { defineComponent } from 'vue';
import {
  sizesFixed,
  props,
  components as viewComponents,
  emits,
} from '@/mixins/view';
import DesktopIcon from '@/components/DesktopIcon.vue';
import IconEmail from '@/components/icons/IconEmail.vue';
import IconInstagram from '@/components/icons/IconInstagram.vue';

export default defineComponent({
  components: {
    ...viewComponents,
    DesktopIcon,
    IconEmail,
    IconInstagram,
  },
  props,
  emits,
  setup(props, { emit }) {
    const onDismiss = () => emit('dismiss');
    return { onDismiss, sizes: sizesFixed(280, 500) };
  },
});
