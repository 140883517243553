
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    defaultOption: {
      type: String,
      default: 'Choose size',
    },
    options: {
      type: Array,
      required: true,
    },
    optionTitleKey: {
      type: String,
      default: 'title',
    },
    optionKeyKey: {
      type: String,
      default: 'id',
    },
    optionValueKey: {
      type: String,
      default: 'id',
    },
    optionDisabledKey: {
      type: String,
      default: 'disabled',
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const selected = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit('update:modelValue', value);
      },
    });
    return { selected };
  },
});
