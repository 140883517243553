
import { mixin as windowMixin, emits as windowEmits } from '@/mixins/window';
import { defineComponent } from 'vue';
import IconPxCross from './icons/IconPxCross.vue';
import IconDocument from './icons/IconDocument.vue';
import IconPxPlus from './icons/IconPxPlus.vue';

export default defineComponent({
  components: {
    IconPxCross,
    IconDocument,
    IconPxPlus,
  },

  props: {
    allowResize: {
      type: Boolean,
      default: true,
    },
    allowMaximize: {
      type: Boolean,
      default: true,
    },
    scrollY: {
      type: Boolean,
      default: true,
    },
    containerClass: {
      type: String,
      default: 'p-2',
    },
    contentClass: {
      type: String,
      default: 'bg-white',
    },
    headerBackground: {
      type: [String, Array, Object],
      default: 'pattern-lines',
    },
    showBorders: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      default: 'basic',
    },
    x: {
      type: Number,
      required: true,
    },
    y: {
      type: Number,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
  },
  emits: windowEmits,
  setup(props, { emit }) {
    const { el, barEl, resizerEl, dismiss, focus, maximize } = windowMixin({
      startX: props.x,
      startY: props.y,
      startW: props.width,
      startH: props.height,
      emit,
    });

    return {
      el,
      barEl,
      resizerEl,
      dismiss,
      focus,
      maximize,
    };
  },
});
