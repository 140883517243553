
import { computed, defineComponent, markRaw, nextTick, ref, watch } from 'vue';
import { useQuery, useResult } from '@vue/apollo-composable';
import { useRouter } from '@/router';
import { useStore } from '@/store';
import ProductQuery from '@/graphql/queries/Product.gql';
import Image from '@/views/basic/Image.vue';
import SizeChart from '@/components/SizeChart.vue';
import BaseException from '@/components/BaseException.vue';
import BaseNotification from '@/components/BaseNotification.vue';
import InputSelect from '@/components/InputSelect.vue';
import {
  props,
  components as viewComponents,
  emits,
  sizes,
} from '@/mixins/view';
import { getNodes, setDefaultVariantTitle } from '@/util/transformers';
import { formatPrice } from '@/util/helpers';
import { notification as notificationMixin } from '@/mixins/notification';
import { ResponseProduct } from '@/types/responses';
import { ShopifyProductImage } from '@/types/product';
import { trackViewItem } from '@/plugins/gtag';

export default defineComponent({
  components: {
    BaseException,
    BaseNotification,
    InputSelect,
    SizeChart,
    ...viewComponents,
  },
  props,
  emits,
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();
    const { result, loading, onResult } = useQuery<ResponseProduct>(
      ProductQuery,
      {
        handle: props.view?.param,
      }
    );

    onResult((res: { data: ResponseProduct }) => {
      const product = res.data.productByHandle;
      if (product) {
        trackViewItem({
          item_id: product.id,
          item_name: product.title,
        });
      }
    });

    const {
      notification,
      notificationAddListeners,
      notificationRemoveListeners,
    } = notificationMixin();
    // Product properties
    const title = useResult(
      result,
      'Not Found',
      (data) => data.productByHandle.title
    );
    const product = useResult(result, null, (data) => data.productByHandle);
    const variants = useResult(result, [], (data) =>
      getNodes(data.productByHandle.variants).map((variant) => ({
        ...setDefaultVariantTitle(variant),
        disabled: !variant.availableForSale,
      }))
    );
    const isSoldOut = computed(() => {
      return variants.value.every((variant) => !variant.availableForSale);
    });
    const price = useResult(result, '', (data) => {
      const {
        minVariantPrice,
        maxVariantPrice,
      } = data.productByHandle.priceRange;
      const prices = [minVariantPrice, maxVariantPrice]
        .map((price) => parseFloat(price.amount))
        .filter((amount, index, self) => self.indexOf(amount) === index)
        .map(formatPrice)
        .join(' – ');
      return prices;
    });
    const images = useResult(result, [], (data) =>
      getNodes(data.productByHandle.images)
    );

    const selectedVariantId = ref('');
    const selectedVariant = computed(() =>
      variants.value.find((variant) => variant.id === selectedVariantId.value)
    );

    // Methods
    const addToCart = () => {
      const p = product.value;
      const v = selectedVariant.value;
      if (p && v?.availableForSale) {
        const added = store.commit.cartAdd(p, v);
        notification.value = false;
        nextTick(() => {
          const audio = new Audio('alert-quiet.mp3');
          audio.play();
          notification.value = added ? true : 'error';
        });
      }
    };

    const onDismiss = () => emit('dismiss');

    const onClickImage = (image: ShopifyProductImage) => {
      router.pushWindow({
        id: image.id,
        component: markRaw(Image),
        props: {
          id: image.id,
          src: image.transformedSrc,
          alt: image.altText,
        },
      });
    };

    return {
      title,
      product,
      variants,
      images,
      price,
      result,
      isSoldOut,
      loading,
      onDismiss,
      onClickImage,
      addToCart,
      selectedVariantId,
      selectedVariant,
      notification,
      notificationAddListeners,
      notificationRemoveListeners,
      sizes: sizes(),
    };
  },
});
