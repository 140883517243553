
import { ref, computed, onBeforeUnmount, PropType, defineComponent } from 'vue';
import { BasedLink } from '@/router/link';
import { RouteLocationRaw } from '@/types/router';
import { ShopProductImage } from '@/types/product';

export default defineComponent({
  props: {
    to: {
      type: [Object, String] as PropType<RouteLocationRaw | string>,
      required: true,
    },
    size: {
      type: String,
      default: '',
    },
    image: {
      type: Object as PropType<ShopProductImage>,
      default: null,
    },
    text: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const root = ref<InstanceType<typeof BasedLink> | null>(null);
    const highlighted = ref(false);
    // @TODO Better mobile detection
    const isMobile =
      typeof navigator !== 'undefined' && /Mobi/i.test(navigator.userAgent);

    const containerWidth = computed(() => {
      if (!props.size) return 'w-12 sm:w-16';
      if (props.size === 'lg') return 'w-32 sm:w-48';
      if (props.size === 'xl') return 'w-48 sm:w-56';
      return props.size;
    });

    function deselect() {
      highlighted.value = false;
    }

    function clickHandler(e: Event) {
      const el = root.value?.$el || root.value;
      if (el && el.contains && !el.contains(e.target)) {
        document.removeEventListener('click', clickHandler);
        deselect();
      }
    }

    function select() {
      highlighted.value = true;
      document.addEventListener('click', clickHandler);
    }

    function onClick() {
      if (highlighted.value || isMobile) {
        deselect();
      } else {
        select();
      }
    }

    const icon = computed(() => {
      return {
        src: props.image ? props.image.thumbnailLarge : '',
        alt: props.image?.altText,
      };
    });

    const preventNavigation = computed(() => {
      if (isMobile) return false;
      if (highlighted.value) return false;
      return true;
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', clickHandler);
    });

    return {
      icon,
      root,
      onClick,
      highlighted,
      containerWidth,
      preventNavigation,
    };
  },
});
